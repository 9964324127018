import React from "react";

import {Box, ListItemButton} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";

import {IconComponent} from "components/IconComponent";
import {StyledListItem} from "layouts/components/DrawerMenu.styles";

export const DrawerMenuItem = ({title, icon, to, disabled, decoration}) => {
    return (
        <StyledListItem key={title} disablePadding disabled={disabled}>
            <ListItemButton component={Link} to={to}>
                <Box pr={1}>
                    <IconComponent icon={icon}/>
                </Box>
                <ListItemText primary={title}/>
                {decoration && <p data-decorator={true}>₽</p>}
            </ListItemButton>
        </StyledListItem>
    );
};
