import { Popover } from "@mui/material";

import { FiltersList } from "components/Table/components/FiltersList";

import { FiltersPopoverInnerWrap } from "./FilterPopover.styles";

export const FilterPopover = ({ anchorEl, handleClose, ...filterProps }) => {
  return (
    <Popover
      anchorEl={anchorEl}
      onClose={handleClose}
      open={!!anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <FiltersPopoverInnerWrap py={1} px={2}>
        <FiltersList {...filterProps} />
      </FiltersPopoverInnerWrap>
    </Popover>
  );
};
