import styled from "@emotion/styled";

export const StyledInput = styled.input`
  display: none;
`;

// get props
export const ComponentWrap = styled.div`
  border: ${props => props.isDragActive ? '2px' : '0'} dashed ${(p) => p.theme.palette.primary.main};
  padding: ${props => props.isDragActive ? '0' : '2px'};
`