import {Chat} from "templates/Chat";
import {Composition} from "templates/Composition";
import Dashboard from "templates/Dashboard/Dashboard";
import {DataList} from "templates/DataList";
import {Form} from "templates/Form";
import {Grid} from "templates/Grid";
import {Log} from "templates/Log";

export const ResolvedTemplate = (props) => {
    switch (props?.meta?.template) {
        case "dashboard":
            return <Dashboard {...props} />;
        case "grid":
            return <Grid {...props} />;
        case "form":
            return <Form {...props} />;
        case "composition":
            return <Composition {...props} />;
        case "data_list":
            return <DataList {...props} />;
        case "log":
            return <Log {...props} />;
        case "chat":
            return <Chat {...props} />
        default:
            return <></>;
    }
};
