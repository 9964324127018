import {useEffect, useState} from "react";

import {Box, Tooltip, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {formatDateTime, formatDateTimeFromNow} from "helpers/dateTime";

export const ChatMessageDateTime = ({created}) => {
    const [formattedTime, setFormattedTime] = useState(formatDateTimeFromNow(created));

    useEffect(() => {
        const timer = setInterval(() => {
            setFormattedTime(formatDateTimeFromNow(created));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [created]);

    return <Tooltip title={formatDateTime(created)} placement="top">
        <Typography component={Box} fontWeight={300} color={grey['500']} fontSize={'12px'}>
            {formattedTime}
        </Typography>
    </Tooltip>
}