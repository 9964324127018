import React, {useContext} from "react";

import {Box, Drawer, List} from "@mui/material";

import {AppContext} from "contexts/AppContext";
import {DrawerMenuItem} from "layouts/components/DrawerMenuItem";

export const DrawerMenu = ({isOpen, drawerWidth}) => {
    const {sideMenu} = useContext(AppContext);

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                },
            }}
            variant="persistent"
            anchor="left"
            open={isOpen}
        >
            <Box>
                <List>
                    {sideMenu.map(({icon, label, link, disabled, decoration}) => (
                        <DrawerMenuItem key={link + label} title={label} to={link} icon={icon} disabled={disabled}
                                        decoration={decoration}/>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};
