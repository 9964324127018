import React from "react";

import {Tooltip} from "@mui/material";
import {markupParser} from "helpers/markupParser";

import {IconComponent} from "components/IconComponent";
import {StyledIconWrap, TitleWrap} from "components/TextInput/TextFieldHelpInfo.styles";

export const TextFieldHelpInfo = ({info}) => {
    return <Tooltip title={<TitleWrap>{markupParser(info)}</TitleWrap>} placement="top">
        <StyledIconWrap>
            <IconComponent icon='questionMark'/>
        </StyledIconWrap>
    </Tooltip>
}