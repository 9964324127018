import { useState } from "react";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { IconButton } from "@mui/material";

import { FilterPopover } from "./FilterPopover";
import { HeaderFiltersWrap } from "./HeaderFilters.styles";

export const HeaderFilters = ({ column, filterControls }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  if (!filterControls.filters[column]) {
    return <></>;
  }

  const togglePopover = (e) => {
    setAnchorEl((anchorEl) => (anchorEl ? null : e.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeFilterState = filterControls.activeFilters[column];

  const isActive = !!activeFilterState;

  return (
    <HeaderFiltersWrap>
      <IconButton
        onClick={togglePopover}
        color={isActive ? "primary" : "default"}
      >
        <FilterAltIcon />
      </IconButton>
      <FilterPopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        filters={filterControls.filters[column]}
        activeFilters={activeFilterState || {}}
        onFilterChange={filterControls.onFilterChange(column)}
      />
    </HeaderFiltersWrap>
  );
};
