import React, {useState} from "react";

import {FormControl, FormHelperText, FormLabel} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {withRules} from "templates/Form/withRules";

import {FileInputField} from "components/FileInput/FileInputField";

const FileInputComponent = (props) => {
    const {
        name,
        helperText,
        fullWidth,
        size = "small",
        label,
        value,
        upload_template: uploadTemplate,
    } = props;

    const {
        control,
        formState: {errors: formErrors},
    } = useFormContext();


    const [uploadError, setUploadError] = useState(null)


    const errors = formErrors?.[name];
    const error = !!errors || !!uploadError;

    const fieldHelperText = uploadError || (error ? errors?.message : helperText);

    return (
        <FormControl
            variant="outlined"
            fullWidth={fullWidth}
            error={error}
            size={size}
        >
            <FormLabel>{label}</FormLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={value || ''}
                render={({field}) => (
                    <div>
                        <FileInputField
                            {...field}
                            uploadTemplate={uploadTemplate}
                            setUploadError={setUploadError}
                        />
                        <FormHelperText>{fieldHelperText}</FormHelperText>
                    </div>
                )}
            />
        </FormControl>)
}

export const FileInput = withRules(FileInputComponent)