import { Grid } from "@mui/material";
import styled from "styled-components";

export const FooterLineWrap = styled(Grid)`
  justify-content: right;
  display: flex;
  padding: 0.2em 0.4em;
  background: ${(p) => p.theme.palette.common.white};
  white-space: nowrap;
`;
