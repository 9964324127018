import { TableCell } from "@mui/material";
import styled from "styled-components";

export const HeaderCell = styled(TableCell)`
  height: 74px;

  & > div {
    font-weight: bold;
  }
`;

export const HeaderSortHandler = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HeaderCellInnerWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
