import React, {useContext, useEffect, useState} from "react";

import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {Breadcrumbs} from "components/Breadcrumbs";
import {Footer} from "components/Footer";
import PageHeader from "components/PageHeader/PageHeader";
import {AppContext} from "contexts/AppContext";
import {DrawerMenu} from "layouts/components/DrawerMenu";
import TopBar from "layouts/components/TopBar";
import {ContentContainer, Main} from "layouts/MainLayout.styles";

const MainLayout = ({
                        barLabel,
                        drawerComponent: DrawerComponent = DrawerMenu,
                        children,
                        meta,
                        errorCode,
                    }) => {
    const {is_authenticated, loading} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && !is_authenticated) {
            navigate("/login");
        }
    }, [is_authenticated, loading]);

    const [isDrawerMenuOpen, setDrawerMenuOpen] = useState(false);

    const drawerWidth = 250;

    const toggleDrawer = (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerMenuOpen(!isDrawerMenuOpen);
    };

    return is_authenticated ? (
        <Box sx={{display: "flex"}}>
            <DrawerComponent
                toggleDrawer={toggleDrawer}
                isOpen={isDrawerMenuOpen}
                drawerWidth={drawerWidth}
            />
            <Main open={isDrawerMenuOpen} drawerWidth={drawerWidth}>
                <TopBar toggleDrawer={toggleDrawer} label={barLabel}/>
                <ContentContainer>
                    {!errorCode && (
                        <>
                            <Breadcrumbs path={meta?.breadcrumbs}/>
                            <PageHeader label={meta?.label}/>
                        </>
                    )}
                    {children}
                </ContentContainer>
                <Footer/>
            </Main>
        </Box>
    ) : (
        <></>
    );
};

export default MainLayout;
