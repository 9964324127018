import {Box, IconButton} from "@mui/material";

import {IconComponent} from "components/IconComponent";

export const ChatClose = ({onClose}) => {
    return <Box pt={1} pr={1} position='absolute' right={0} top={0}>
        <IconButton onClick={onClose}>
            <IconComponent icon={'close'}/>
        </IconButton>
    </Box>
}