import React, {Fragment, useContext, useRef} from "react";

import {IconButton, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {IconComponent} from "components/IconComponent";
import {TRActionMenu} from "components/Table/components/Actions/TRActionMenu";
import {GridContext} from "contexts/GridContext";
import {ModalContext} from "contexts/ModalContext";
import {TemplateContext} from "contexts/TemplateContext";

export const TRButton = ({label, onClick, icon, visible, items}) => {
    const [open, setOpen] = React.useState(false);
    const buttonRef = useRef(null);

    const {openModal} = useContext(ModalContext);
    const {reloadGridData} = useContext(GridContext);
    const {reload: reloadTemplate} = useContext(TemplateContext)
    const navigate = useNavigate();

    const openItems = () => {
        setOpen(true);
    }

    const onClickHandler = (onClick) => (e) => {
        setOpen(false)
        return onClick(e, {openModal, reloadGridData, navigate, reloadTemplate, openItems});
    };

    return visible ? (
        <Fragment key={label}>
            <Tooltip title={label}>
                <IconButton onClick={onClickHandler(onClick)} ref={buttonRef}>
                    <IconComponent icon={icon}/>
                </IconButton>
            </Tooltip>
            {!!items && items.length && <TRActionMenu
                items={items}
                isOpen={open}
                onClose={() => setOpen(false)}
                anchorEl={buttonRef.current}
                onClickHandler={onClickHandler}
            />}
        </Fragment>
    ) : (
        <Fragment key={label}/>
    );
}