import styled from "@emotion/styled";
import {Box} from "@mui/material";

import {IconComponent} from "components/IconComponent";

export const ChatContentWrap = styled(Box)`
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 2em;
`

export const ChatWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const MessageLoadWrap = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NoConnectionWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 4em;
`

export const NoConnectionText = styled(Box)`
  font-size: 1.2em;
  color: ${p => p.theme.palette.grey['500']};
  font-weight: 400;
`

export const NoConnectionIcon = styled(IconComponent)`
  font-size: 4em;
  color: ${p => p.theme.palette.grey['500']};
`