import {useMemo} from "react";

import {Button} from "@mui/material";
import {useFormContext} from "react-hook-form";
import {withRules} from "templates/Form/withRules";

import {SubmitCircularLoader} from "components/ApiForm/components/SubmitCircularLoader";

const SubmitInputComponent = ({label, canSubmitClean = false}) => {
    const {
        isApiLoading,
        formState: {isLoading, isValid, isDirty},
    } = useFormContext();

    const disabled = useMemo(() => {
        if (isApiLoading || isLoading || !isValid) {
            return true
        }
        if (!isDirty && !canSubmitClean) {
            return true
        }

        return false
    }, [isLoading, isValid, isDirty, canSubmitClean, isApiLoading])

    return (
        <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={disabled}
            fullWidth
        >
            {(isLoading || isApiLoading) && <SubmitCircularLoader/>}
            {label}
        </Button>
    );
};

export const SubmitInput = withRules(SubmitInputComponent);
