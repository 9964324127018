import {Box, Chip, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {formatDateTime} from "helpers/dateTime";
import {EventMessage} from "templates/Log/components/EventMessage";

export const LogRecord = ({
                              created_at,
                              event_type,
                              data,
                              related_type,
                              related_id,
                              is_admin,
                              showRecordLabel,
                              modelsLogMeta,
                          }) => {
    return (
        <Box>
            <Chip
                color={!is_admin ? "primary" : "success"}
                label={!!is_admin ? "Администратор" : "Пользователь"}
                variant="outlined"
            />
            <Box pl={1}>
                <Typography variant="caption" display="block" color={grey[600]}>
                    {formatDateTime(created_at)}
                </Typography>
            </Box>

            <EventMessage
                eventType={event_type}
                data={data}
                relatedType={related_type}
                relatedId={related_id}
                showRecordLabel={showRecordLabel}
                modelsLogMeta={modelsLogMeta}
            />
        </Box>
    );
};
