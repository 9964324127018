import {DatePicker as MuiDatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {Controller} from "react-hook-form";

import {StyledTextField} from "components/TextInput";

export const DatePicker = ({control, name, value, ...props}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({field: {ref, value, ...rest}}) => {
                return (
                    <MuiDatePicker
                        {...props}
                        inputRef={ref}
                        {...rest}
                        value={value ? dayjs(value) : null}
                        onChange={(val) => rest.onChange(val ? val.format("YYYY-MM-DD") : val)}
                        slots={{
                            textField: StyledTextField,
                        }}
                    />
                );
            }}
        />
    );
};
