import {ChatIconWrap, StyledBadge, StyledIconButton, StyledIconComponent} from "components/ChatIcon/ChatIcon.styles";

export const ChatIcon = ({
                             messageCount,
                             onClick = null
                         }) => {
    return (
        <ChatIconWrap>
            <StyledBadge badgeContent={messageCount} color="error">
                <StyledIconButton onClick={onClick}>
                    <StyledIconComponent icon='chat'/>
                </StyledIconButton>
            </StyledBadge>
        </ChatIconWrap>
    )
}