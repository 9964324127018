import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import {Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import styled from "styled-components";

export const GridLinkStyled = styled(Link)`
  color: ${(p) => p.theme.palette.text.primary};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export const IconContainer = styled.div`
  color: ${(p) => p.theme.palette.grey[800]};

  svg {
    height: 4em;
    width: 4em;
  }
`;

export const StyledPaper = styled(Paper)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &[disabled] > *:not([data-decorator]) {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`;

export const StyledMenuHeader = styled(Typography)`
  text-align: center;
  font-weight: 600;
  padding-left: 1em;
  align-self: flex-start;
`;

export const ActionsWrap = styled.div`
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
`;

export const StatisticWrap = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  margin-top: 0;
`;

export const StyledHelpIcon = styled(HelpIcon)`
  color: ${(p) => p.theme.palette.grey[700]};
`;

export const StyledErrorIcon = styled(ErrorIcon)`
  color: ${(p) => p.theme.palette.error.main};
`;

export const TopIconsBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
`;

export const HelpIconWrap = styled.div`
  align-self: flex-end;
`;

export const WarningIconWrap = styled.div`
  align-self: flex-start;
`;
