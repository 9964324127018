import React, { useCallback, useMemo, useState } from "react";

export const useModal = () => {
  const [modalProps, setModalProps] = useState(false);

  const close = useCallback(() => setModalProps(false), []);

  const openModal = useCallback((props) => {
    setModalProps(props);
  }, []);

  const modal = useMemo(() => {
    const onConfirm = () => {
      modalProps.onConfirm();
      close();
    };

    if (!!modalProps) {
      const ModalComponent = modalProps.component;
      return (
        <ModalComponent
          open={true}
          handleClose={close}
          onConfirm={onConfirm}
          {...modalProps}
        />
      );
    }

    return <></>;
  }, [modalProps, close]);

  return { modal, openModal };
};
