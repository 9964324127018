import { Grid } from "@mui/material";

import { Filter } from "components/Table/components/Filter";

export const FiltersList = ({
  filters = [],
  activeFilters = {},
  onFilterChange,
  firstElementAutoFocus = true,
}) => {
  return (
    <>
      {filters.map((filter, index) => (
        <Grid item py={1} key={filter.label}>
          <Filter
            filter={filter}
            activeValue={activeFilters[index]}
            onFilterChange={onFilterChange(index)}
            autoFocus={index === 0 ? firstElementAutoFocus : false}
          />
        </Grid>
      ))}
    </>
  );
};
