import { Typography } from "@mui/material";

import { CleanLink } from "../CleanLink";

export const StyledLink = (props) => {
  return (
    <Typography color="primary" component="span">
      <CleanLink {...props} />
    </Typography>
  );
};
