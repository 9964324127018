import React from "react";

import { FormContainerStyled } from "components/FormContainer/FormContainer.styled";

export const FormContainer = ({ children, onSubmit }) => {
  return (
    <FormContainerStyled component="form" onSubmit={onSubmit}>
      {children}
    </FormContainerStyled>
  );
};
