import { LinearProgress } from "@mui/material";
import { grey } from "@mui/material/colors";

import { TableLoaderWrap } from "components/Table/components/TableLoader.styles";

export const TableLoader = ({ loading }) => {
  return (
    <tr style={{ borderBottom: `1px solid ${grey["300"]}` }}>
      <td colSpan="100%">
        <TableLoaderWrap>{loading && <LinearProgress />}</TableLoaderWrap>
      </td>
    </tr>
  );
};
