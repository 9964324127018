import { useContext } from "react";

import { useFormContext } from "react-hook-form";

import { ConfirmationModal } from "components/ConfirmationModal";
import { ModalContext } from "contexts/ModalContext";

export const useFieldRules = (name, rules = []) => {
  const res = {};

  const { watch, setValue } = useFormContext();

  const { openModal } = useContext(ModalContext);

  const confirmChangeModal = (targetState, modalText, confirmText) => (e) => {
    e.target.checked === targetState
      ? openModal({
          component: ConfirmationModal,
          title: modalText,
          submitButtonText: confirmText,
          onConfirm: () => {
            setValue(name, targetState);
          },
        })
      : setValue(name, !targetState);
  };
  rules.forEach((rule) => {
    const [ruleName, ...params] = rule;

    if (ruleName === "disable_if_field_have_value") {
      const [field, ...otherParams] = params;
      res.disabled = watch(field);
    }

    if (ruleName === "confirm_modal_on_state") {
      const [state, modalText, confirmText] = params;
      res.onChange = confirmChangeModal(state, modalText, confirmText);
    }

    if (ruleName === "clean_field_on_state") {
      const [state, field] = params;
      if (watch(name) === state) {
        setTimeout(() => {
          setValue(field, null);
        }, 0);
      }
    }

    if (ruleName === "hide_if_field_empty") {
      const [field] = params;
      if (!watch(field)) {
        res.hidden = true;
      }
    }
  });

  return res;
};
