import React, {useContext, useEffect} from "react";

import {Container, Grid, Paper, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {CleanLink} from "components/CleanLink";
import {Footer} from "components/Footer";
import {AppContext} from "contexts/AppContext";

import {ContainerWrap, LayoutWrap} from "./AuthLayout.styles";

const AuthLayout = ({children, meta}) => {
    const {is_authenticated, loading} = useContext(AppContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && is_authenticated) {
            navigate("/");
        }
    }, [is_authenticated, loading]);

    return !is_authenticated ? (
        <LayoutWrap>
            <ContainerWrap container>
                <Paper component={Grid} xs={11} sm={10} md={6} lg={4} pb={2} item>
                    <Container>
                        <h3>{meta?.label}</h3>
                        {children}
                        {meta?.links?.map(({label, link}) => (
                            <Typography key={link} color="primary" textAlign="center">
                                <CleanLink to={link}>{label}</CleanLink>
                            </Typography>
                        ))}
                    </Container>
                </Paper>
            </ContainerWrap>
            <Footer/>
        </LayoutWrap>
    ) : (
        <></>
    );
};

export default AuthLayout;
