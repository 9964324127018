import styled from "@emotion/styled";
import { InputLabel } from "@mui/material";

export const SelectLabel = styled(InputLabel)`
  margin: 0 -5px;
  padding: 0 5px;

  &:before {
    content: "";
    background-color: white;
    width: 100%;
    position: absolute;
    left: 0;
    height: 12px;
    top: 10px;
    z-index: -1;
  }

  //background: 0 50% white;
`;
