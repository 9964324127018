import styled from "@emotion/styled";
import {Box} from "@mui/material";
import {green} from "@mui/material/colors";

import {IconComponent} from "components/IconComponent";

export const NoMessagesWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 4em;
`

export const NoMessagesText = styled(Box)`
  font-size: 1.2em;
  color: ${p => p.theme.palette.grey['500']};
  font-weight: 400;
`

export const NoMessagesIcon = styled(IconComponent)`
  font-size: 4em;
  color: ${p => p.theme.palette.grey['500']};

`

export const Message = styled(Box)`
  @keyframes colorChange {
    0% {
      background-color: ${p => green['300']};
    }
    100% {
      background-color: white;
    }
  }

  &[data-is-new='true'] {
    animation: ease-in-out colorChange 2s;
  }
`