import DangerousIcon from "@mui/icons-material/Dangerous";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styled from "styled-components";

export const DeleteForeverIconStyled = styled(DeleteForeverIcon)`
  &:hover {
    color: ${(p) => p.theme.palette.error.dark};
  }
`;

export const InventoryIconStyled = styled(Inventory2Icon)`
  &:hover {
    color: ${(p) => p.theme.palette.error.dark};
  }
`;

export const EditIconStyled = styled(EditIcon)`
  &:hover {
    color: ${(p) => p.theme.palette.primary.dark};
  }
`;

export const VisibilityIconStyled = styled(VisibilityIcon)`
  &:hover {
    color: ${(p) => p.theme.palette.primary.dark};
  }
`;

export const DownloadIconStyled = styled(DownloadIcon)`
  &:hover {
    color: ${(p) => p.theme.palette.success.dark};
  }
`;

export const DangerousIconStyled = styled(DangerousIcon)`
  &:hover {
    color: ${(p) => p.theme.palette.error.dark};
  }
`;
export const PlayCircleIconStyled = styled(PlayCircleIcon)`
  &:hover {
    color: ${(p) => p.theme.palette.success.main};
  }
`;

export const PauseCircleIconStyled = styled(PauseCircleIcon)`
  &:hover {
    color: ${(p) => p.theme.palette.warning.main};
  }
`;
