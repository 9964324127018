import React from "react";

import parse from "html-react-parser";

import { StyledLink } from "components/StyledLink";

export const markupParser = (label = "") => {
  const options = {
    replace: ({ name, attribs, data, children }) => {
      if (name === "link") {
        return <StyledLink {...attribs} />;
      }
    },
  };

  return parse(label, options);
};
