import {Box, Grid} from "@mui/material";
import styled from "styled-components";

export const ContainerWrap = styled(Grid)`
  min-width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.palette.primary.main};
  flex-direction: column;
  padding: 8em 0;
  ${(p => p.theme.configPoints.layouts.auth.containerWrap)}
`;

export const LayoutWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
