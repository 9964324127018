import {useFormContext} from "react-hook-form";
import {withRules} from "templates/Form/withRules";

import {DatePicker} from "components/DatePicker";

const DatePickerComponent = ({name, ...props}) => {
    const {
        control,
        formState: {errors: formErrors},
    } = useFormContext();

    const errors = formErrors?.[name];
    const error = !!errors;

    return (
        <DatePicker
            {...props}
            name={name}
            control={control}
            slotProps={{
                field: {
                    fullWidth: true,
                    size: "small",
                    error,
                    helperText: errors?.message,
                },
            }}
        />
    );
};

export const DatePickerInput = withRules(DatePickerComponent)