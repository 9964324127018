import {useContext} from "react";

import {Typography} from "@mui/material";

import {CleanLink} from "components/CleanLink";
import {AppContext} from "contexts/AppContext";

export const BlockedText = () => {
    const {support_link} = useContext(AppContext)

    return <>
        <Typography variant='h6' align="center">Аккаунт заблокирован</Typography>
        {support_link && <Typography>
            Пожалуйста, обратитесь в
            <CleanLink to={support_link} target='_blank'> службу поддержки </CleanLink>
            для разблокировки
        </Typography>}
    </>
}