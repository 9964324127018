import { Checkbox, FormControlLabel } from "@mui/material";

export const CheckboxFilter = ({ label, value = false, onChange }) => {
  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      label={label}
      checked={value}
      onChange={handleChange}
    />
  );
};
