import React from "react";

import {TRButton} from "components/Table/components/Actions/TRButton";

import {ActionBox} from "./TableRowActions.styles";

const TableRowActions = ({actions = []}) => {


    return (
        <ActionBox>
            {actions.map((props) => {
                return <TRButton key={props.label} {...props}/>
            })}
        </ActionBox>
    );
};

export default TableRowActions;
