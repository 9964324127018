import {Tooltip} from "@mui/material";
import {
    NegativeBalanceOverlay,
    PaidPlanRequiredOverlay,
    StyledDevOverlay
} from "templates/Dashboard/components/ModelWidget/WidgetDecorator.styles";

export const WidgetDecorator = ({decoration}) => {
    if (decoration == null) {
        return null;
    }
    if (decoration === "development") {
        return <StyledDevOverlay data-decorator>
            <div>в разработке</div>
        </StyledDevOverlay>
    }

    if (decoration === "balance_negative") {
        return <NegativeBalanceOverlay data-decorator>
            <Tooltip title="Требуется пополнить баланс">
                <div>₽</div>
            </Tooltip>
        </NegativeBalanceOverlay>
    }

    if (decoration === "paid_plan_required") {
        return <PaidPlanRequiredOverlay data-decorator>
            <Tooltip title="Доступно по платной подписке">
                <div>₽</div>
            </Tooltip>
        </PaidPlanRequiredOverlay>
    }
}