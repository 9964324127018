import React from "react";

import {Grid} from "@mui/material";
import ModelWidget from "templates/Dashboard/components/ModelWidget/ModelWidget";

const Dashboard = ({data: {widgets}}) => {
    return (
        <div>
            <Grid container spacing={2} pb={1}>
                {widgets.map(({icon, label, link, tooltip, stat, warning, target, disabled, decoration}) => (
                    <ModelWidget
                        key={label}
                        icon={icon}
                        label={label}
                        to={link}
                        tooltipText={tooltip}
                        stat={stat}
                        warning={warning}
                        target={target}
                        disabled={disabled}
                        decoration={decoration}
                    />
                ))}
            </Grid>
        </div>
    );
};

export default Dashboard;
