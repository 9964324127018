import { createContext } from "react";

import { useModal } from "hooks/useModal";

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
  const { modal, openModal } = useModal();
  return (
    <ModalContext.Provider value={{ modal, openModal }}>
      {children}
      {modal}
    </ModalContext.Provider>
  );
};
