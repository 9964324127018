import React from "react";

import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

export const ConfirmationModal = ({
  title,
  handleClose,
  onConfirm,
  open,
  submitButtonText = "Подтвердить",
  cancelButtonText = "Отмена",
}) => {
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {cancelButtonText}
        </Button>
        <Button onClick={handleConfirm}>{submitButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};
