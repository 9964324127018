import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { SortIndicatorWrap } from "./SortIndicator.styles";

export const SortIndicator = ({ isSorted }) => {
  return (
    <SortIndicatorWrap>
      {isSorted ? (
        isSorted === "desc" ? (
          <ArrowDropDownIcon />
        ) : (
          <ArrowDropUpIcon />
        )
      ) : (
        <></>
      )}
    </SortIndicatorWrap>
  );
};
