import dayjs from "dayjs";

const dateTimeDisplayFormat = "DD.MM.YYYY HH:mm";
const dateDisplayFormat = "DD.MM.YYYY";

export const formatDateTime = (val) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || "Europe/Moscow";

    return dayjs(val).utc().tz(userTimezone).format(dateTimeDisplayFormat);
};

export const formatDateTimeFromNow = (val) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || "Europe/Moscow";

    return dayjs(val).utc().tz(userTimezone).fromNow();
}

export const formatDateTimeUnix = (val) => {
    return dayjs.unix(val).format(dateTimeDisplayFormat);
};

export const formatDate = (val) => {
    return dayjs(val).format(dateDisplayFormat);
};
