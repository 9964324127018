import React from "react";

import {Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

import {CheckboxInput} from "components/CheckboxInput";
import {DatePickerInput} from "components/DatePickerInput";
import {DictTextInput} from "components/DictTextInput/DictTextInput";
import {DropdownSelect} from "components/DropdownSelect";
import {FileInput} from "components/FileInput";
import {RadioInput} from "components/RadioInput";
import {SubmitInput} from "components/SubmitInput";
import {TextInput} from "components/TextInput";

export const formFieldsResolver = (fields = []) =>
    Object.keys(fields).map((name) => {
        const {template, ...rest} = fields[name];

        switch (template) {
            default:
            case "text":
                return <TextInput key={rest.name} {...rest} />;
            case "checkbox":
                return <CheckboxInput key={rest.name} {...rest} />;
            case "dropdown":
                return <DropdownSelect key={rest.name} {...rest} />;
            case "submit":
                return <SubmitInput key={rest.name} {...rest} />;
            case "textDict":
                return <DictTextInput key={rest.name} {...rest} />;
            case "date":
                return <DatePickerInput key={rest.name} {...rest} />;
            case "radio":
                return <RadioInput key={rest.name} {...rest} />;
            case "file":
                return <FileInput key={rest.name} {...rest} />;
            case "sublabel":
                return <Typography
                    variant="subtitle1"
                    py={1}
                    color={grey[700]}
                    key={rest.label}
                >
                    {rest.label}
                </Typography>
        }
    });
