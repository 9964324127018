import React, {useCallback} from "react";

import {FormControl, FormHelperText} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {withRules} from "templates/Form/withRules";

import {InputSublabel, InputSublabelPrefix} from "components/DropdownSelect/DropdownSelect.styles";

import {useDictApi} from "../../hooks/useDictApi";
import {ReactSelect} from "../ReactSelect/ReactSelect";

const DropdownSelectComponent = (props) => {
    const {
        name,
        helperText,
        items,
        fullWidth,
        size = "small",
        label,
        multi,
        dependsOn = [],
        remoteDict,
        required,
        value,
        sublabelPrefix,
    } = props;


    const {
        control,
        formState: {errors: formErrors},
        watch,
    } = useFormContext();

    const errors = formErrors?.[name];
    const error = !!errors;

    const fieldHelperText = error ? errors?.message : helperText;

    const {items: loadedItems} = useDictApi({
        remoteDict,
        dependsOn: dependsOn.reduce((acc, cur) => {
            acc[cur] = watch(cur);
            return acc;
        }, {}),
    });

    const labelWithRequiredMark = required ? label + " *" : label;

    const formatOptionLabel = useCallback(({label, sublabel}) => (
        <div>
            <div>{label}</div>
            {sublabel && (<InputSublabel>
                <InputSublabelPrefix>
                    {sublabelPrefix}
                </InputSublabelPrefix>
                {sublabel}
            </InputSublabel>)}
        </div>
    ), [sublabelPrefix]);

    return (
        <FormControl
            variant="outlined"
            fullWidth={fullWidth}
            error={error}
            size={size}
        >
            <Controller
                name={name}
                control={control}
                defaultValue={value}
                render={({field: {ref, ...rest}}) => (
                    <ReactSelect
                        label={labelWithRequiredMark}
                        placeholder={label}
                        multi={multi}
                        items={loadedItems || items}
                        formatOptionLabel={formatOptionLabel}
                        {...rest}
                    />
                )}
            />
            <FormHelperText>{fieldHelperText}</FormHelperText>
        </FormControl>
    );
};
export const DropdownSelect = withRules(DropdownSelectComponent);
