import { DateFilter } from "components/Table/components/Filter/components/DateFilter";
import { TextFilter } from "components/Table/components/Filter/components/TextFilter";

import { CheckboxFilter } from "./components/CheckboxFilter";
import { DropdownFilter } from "./components/DropdownFilter";
import { MultiselectFilter } from "./components/MultiselectFilter";

export const Filter = ({
  filter,
  activeValue,
  onFilterChange,
  autoFocus = false,
}) => {
  const value = activeValue != null ? activeValue : filter.default_value;
  const filterProps = {
    ...filter,
    autoFocus,
    value,
    onChange: onFilterChange,
  };
  switch (filter.template) {
    case "checkbox":
      return <CheckboxFilter {...filterProps} />;
    case "multiple":
      return <MultiselectFilter {...filterProps} />;
    case "dropdown_filter":
      return <DropdownFilter {...filterProps} />;
    case "text":
      return <TextFilter {...filterProps} />;
    case "date":
      return <DateFilter {...filterProps} />;

    default:
      console.log(`Filter ${filter.template} not found`);
      return <></>;
  }
};
