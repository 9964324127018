import React from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export const NotesModal = ({ handleClose, open, notes }) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Комментарий</DialogTitle>
      <DialogContent>{notes}</DialogContent>
    </Dialog>
  );
};
