import React, {useCallback} from "react";

import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import {useDropzone} from "react-dropzone";

import {CleanLink} from "components/CleanLink";
import {ComponentWrap, StyledInput} from "components/FileInput/FileInput.styles";
import {IconComponent} from "components/IconComponent";
import {useUpload} from "hooks/useUpload";

export const FileInputField = React.forwardRef(({setUploadError, uploadTemplate, ...props}, ref) => {
    const {onChange, value} = props
    const {upload, loading} = useUpload({uploadTemplate})
    const {REACT_APP_API_URL} = process.env;

    const onFieldClean = (e) => {
        e.preventDefault()
        e.stopPropagation()
        onChange('')
    }

    const processFiles = (files) => {
        setUploadError(null)
        upload(files).then(data => {
            if (!data.error) {
                onChange(data.id)
            } else {
                setUploadError(data.error)
            }
        }).catch(error => {
            setUploadError('Ошибка отправки файла')
        })
    }

    const onDrop = useCallback(files => {
        if (files.length) {
            processFiles(files)
        }
    }, [processFiles]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});


    return <ComponentWrap {...getRootProps()} isDragActive={isDragActive}>
        <StyledInput
            type="text"
            {...props}
        />
        <StyledInput
            type="file"
            {...getInputProps()}
        />
        <Box display="flex" alignItems="center" height={54}>
            {!!loading ? <Box p={2}><CircularProgress size={26}/></Box>
                : value ?
                    <IconButton
                        variant="contained"
                        color="error"
                        onClick={onFieldClean}
                    >
                        <IconComponent icon='delete'/>
                    </IconButton>
                    : <IconButton
                        variant="contained"
                        color="success"
                        component="span"
                    >
                        <IconComponent icon='add'/>
                    </IconButton>
            }
            {!!value && (
                <>
                    <Typography>
                        <CleanLink target={"_blank"}
                                   to={REACT_APP_API_URL + '/uploads/download/' + value}
                                   onClick={e => e.stopPropagation()}>
                            Скачать
                        </CleanLink>
                    </Typography>
                </>
            )}
        </Box>
    </ComponentWrap>
})