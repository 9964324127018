import {createContext, Fragment, useContext, useMemo, useState} from "react";

import {useLocation} from "react-router-dom";

import {ErrorCodeResolver} from "components/TemplateResolver/ErrorCodeResolver";
import {ResolvedTemplate} from "components/TemplateResolver/ResolvedTemplate";
import {AppContext} from "contexts/AppContext";
import {useApi} from "hooks/useApi";
import AuthLayout from "layouts/AuthLayout";
import MainLayout from "layouts/MainLayout";

export const TemplateContext = createContext({});

export const TemplateProvider = ({
                                     pathOverride = null,
                                     children,
                                     defaultLayout = "main"
                                 }) => {
    const location = useLocation();
    const endpoint = pathOverride || location.pathname.substring(1) || "root";
    const {meta, data, errorCode, reload: reloadTemplate} = useApi({
        url: endpoint,
    });

    const {is_authenticated} = useContext(AppContext)

    const dataExport = () => {
        console.log("No dataExport fn in this context");
    };

    const {loading: appLoading} = useContext(AppContext);

    const [reloading, setReloading] = useState(false)

    const reload = (...props) => {
        setReloading(true)
        return reloadTemplate(...props).finally(() => setReloading(false))
    }

    const Layout = useMemo(() => {
        const layout = meta?.layout || defaultLayout
        if (layout === "none") {
            return ({children}) => <Fragment>{children}</Fragment>
        }
        if (layout === "main" || is_authenticated) {
            return MainLayout;
        }
        if (layout === "auth") {
            return AuthLayout;
        }

        return MainLayout;
    }, [meta?.layout, defaultLayout, is_authenticated]);

    return (
        <TemplateContext.Provider value={{reload, dataExport, reloading, meta}}>
            <>
                <Layout meta={meta} errorCode={errorCode}>
                    {!errorCode ? (
                        !appLoading && (
                            <ResolvedTemplate data={data} meta={meta} endpoint={endpoint}/>
                        )
                    ) : (
                        <ErrorCodeResolver errorCode={errorCode}/>
                    )}
                </Layout>
                {children}
            </>
        </TemplateContext.Provider>
    );
};
