import React from "react";

import {
  Paper,
  Table as TableMUI,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { ActionGroup } from "components/ActionGroup";
import { NoData } from "components/Table/components/NoData";
import { TableLoader } from "components/Table/components/TableLoader";
import { applyColorRules } from "components/Table/helpers";

import {
  DEFAULT_PER_PAGE_OPTIONS,
  labelDisplayedRows,
} from "../../constants/grid";
import { HeaderFilters } from "./components/HeaderFilters";
import { SortIndicator } from "./components/SortIndicator";
import {
  HeaderCell,
  HeaderCellInnerWrap,
  HeaderSortHandler,
} from "./Table.styles";

const Table = ({
  columns,
  data = [],
  actions = [],
  gridControls: { paginationControls, sortControls, filterControls, loading },
  colorRules = {},
}) => {
  const table = useReactTable({
    columns,
    data,
    state: {
      sorting: sortControls.sort,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: sortControls.onSort,
  });

  return (
    <TableContainer component={Paper}>
      <ActionGroup buttons={actions} />
      <TableMUI>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const canSort = sortControls.sortable.includes(
                  header.column.columnDef.accessorKey
                );
                return (
                  <HeaderCell
                    key={header.id}
                    style={{
                      width: `${header.getSize()}%`,
                      borderBottom: "none",
                    }}
                  >
                    <HeaderCellInnerWrap>
                      <HeaderSortHandler
                        onClick={
                          canSort
                            ? header.column.getToggleSortingHandler()
                            : () => {}
                        }
                        style={{
                          cursor: canSort ? "pointer" : "default",
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        <SortIndicator isSorted={header.column.getIsSorted()} />
                      </HeaderSortHandler>
                      <HeaderFilters
                        column={header.column.columnDef.accessorKey}
                        filterControls={filterControls}
                      />
                    </HeaderCellInnerWrap>
                  </HeaderCell>
                );
              })}
            </TableRow>
          ))}
          <TableLoader loading={loading} />
        </TableHead>
        {data.length ? (
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                style={applyColorRules(colorRules?.row, row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    padding={
                      cell.column.columnDef.disablePadding
                        ? "checkbox"
                        : "normal"
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <NoData colSpan="100%" loading={loading} />
        )}
      </TableMUI>
      <TablePagination
        component="div"
        rowsPerPageOptions={DEFAULT_PER_PAGE_OPTIONS}
        labelRowsPerPage="На странице:"
        labelDisplayedRows={labelDisplayedRows}
        {...paginationControls}
      />
    </TableContainer>
  );
};

export default Table;
