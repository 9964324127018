import {useCallback, useContext, useEffect, useState} from "react";

import {makeApiRequest} from "helpers/makeApiRequest";
import {useNavigate} from "react-router-dom";

import {AppContext} from "contexts/AppContext";

export const useApi = ({
                           url,
                           reset,
                           setError,
                           disableInitialLoad,
                           cleanOnSuccess,
                           onSuccess,
                           requestData = {},
                           initialMethod = "get",
                           responseType = "blob",
                       }) => {
    const [data, setData] = useState(undefined);
    const [meta, setMeta] = useState(undefined);
    const [errorCode, setErrorCode] = useState(undefined);
    const [dictionaries, setDictionaries] = useState({});
    const [loading, setLoading] = useState(!disableInitialLoad);

    const navigate = useNavigate();

    const {reload: reloadAppHandler} = useContext(AppContext);

    const apiRequest = (
        apiRequestData = requestData,
        method = initialMethod,
        fileDownloadName = false
    ) => {
        setLoading(true);
        setErrorCode(null);
        return makeApiRequest({
            config: {
                url,
                data: apiRequestData,
                method,
                responseType,
                fileDownloadName,
            },
        })
            .then(async (response) => {
                setErrorCode(response?.status)

                if (!response) {
                    return;
                }

                if (response === 1) {
                    typeof onSuccess === "function" && onSuccess();
                    return;
                }


                const {
                    data,
                    meta,
                    dictionaries,
                    errors,
                    redirect,
                    reloadApp,
                } = response;

                if (reloadApp) {
                    reloadAppHandler();
                }

                if (data) {
                    setData(data);
                    reset && reset(data);
                }

                if (meta) {
                    setMeta(meta);
                }

                if (errors && Object.keys(errors).length) {
                    setError &&
                    Object.keys(errors).map((key) =>
                        errors[key].forEach((value) => {
                            setError(key, {type: "custom", message: value});
                        })
                    );
                } else if (method !== "get") {
                    typeof onSuccess === "function" && onSuccess(data);

                    if (cleanOnSuccess) {
                        reset &&
                        reset((formValues) => {
                            let newFv = {}
                            Object.keys(formValues).forEach((k) => (newFv[k] = ""))
                            return newFv
                        });
                        setData(undefined);
                    }
                }

                if (dictionaries) {
                    setDictionaries(dictionaries);
                }


                if (redirect) {
                    navigate(redirect);
                }
                return response;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (disableInitialLoad) return;
        apiRequest();
    }, [url]);

    const onSubmit = useCallback(
        (requestData) => {
            return apiRequest(requestData, "post");
        },
        [url]
    );

    const reload = useCallback((data = undefined, method = undefined) => {
        return apiRequest(data, method);
    }, [url, requestData]);

    const dataExport = (fileName) => {
        apiRequest({requestData, export: true}, initialMethod, fileName);
    };

    return {
        data,
        meta,
        setData,
        dictionaries,
        loading,
        onSubmit,
        reload,
        dataExport,
        errorCode,
    };
};
