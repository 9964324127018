import {createContext, useContext, useEffect, useState} from "react";

import {io} from "socket.io-client";

import {AppContext} from "contexts/AppContext";

export const SocketContext = createContext({});

export const SocketProvider = ({children}) => {
    const data = useContext(AppContext)
    const [socket, setSocket] = useState(null)
    const [connected, setConnected] = useState(null)
    const {wsToken} = data

    const onConnect = () => {
        setConnected(true)
    }

    const onDisconnect = () => {
        setConnected(false)
    }

    useEffect(() => {
        let newSocket;

        if (wsToken) {
            newSocket = io(process.env.REACT_APP_SOCKET_URL, {
                auth: {
                    token: wsToken
                }
            });

            setSocket(newSocket);

            newSocket.on('connect', onConnect)
            newSocket.on('disconnect', onDisconnect)
        }

        return () => {
            const activeSocket = socket || newSocket;
            if (activeSocket) {
                activeSocket.disconnect();
            }
        }
    }, [wsToken]);

    useEffect(() => {
        if (socket && !connected) {
            socket.connect()
        }
    }, [socket, connected]);

    return <SocketContext.Provider value={{socket, connected}}>
        {children}
    </SocketContext.Provider>
}