import {ChatInputStyled} from "templates/Chat/components/ChatInput.styles";

import {ApiForm} from "components/ApiForm";

const CHAT_INPUT_FIELDS = [
    {
        name: 'message',
        label: 'Сообщение',
        template: 'text',
        required: true,
        multiline: true,
        submitOnEnter: true,
        maxRows: 4,
    },
    {
        name: 'submit',
        template: 'submit',
        label: 'Отправить',
    },
]

export const ChatInput = ({chat_id}) => {
    return <ChatInputStyled>
        <ApiForm
            fields={CHAT_INPUT_FIELDS}
            apiUrl={'chat/' + chat_id + '/message'}
            cleanOnSuccess={true}
            disableInitialLoad={true}
            // onSuccess={onSuccess}
        />
    </ChatInputStyled>
}