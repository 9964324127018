import {useContext} from "react";

import {Box} from "@mui/material";

import {prepareAgActions} from "components/ActionGroup";
import {Table} from "components/Table";
import {prepareColumns} from "components/Table/helpers";
import {GridContext} from "contexts/GridContext";
import {TemplateContext} from "contexts/TemplateContext";
import {useGridApi} from "hooks/useGridApi";

export const Grid = ({meta: templateMeta, endpoint}) => {
    const templateContext = useContext(TemplateContext);

    const {
        data,
        meta,
        gridControls,
        reload: reloadGridData,
    } = useGridApi({
        url: templateMeta?.apiUrl || endpoint,
    });

    const aggregatedMeta = {
        ...templateMeta,
        ...meta,
    };
    const {columns, headerActions} = aggregatedMeta;

    const tableColumns = prepareColumns(columns);

    const thActions = prepareAgActions(headerActions);

    const reload = () => {
        templateContext.reload().then(() => reloadGridData());
    };

    return (
        <TemplateContext.Provider
            value={{
                ...templateContext,
                dataExport: gridControls.dataExport,
                reload,
            }}
        >
            <GridContext.Provider value={{reloadGridData}}>
                <Box pb={2}>
                    <Table
                        data={data}
                        columns={tableColumns}
                        actions={thActions}
                        gridControls={gridControls}
                        colorRules={aggregatedMeta.colorRules}
                    />
                </Box>
            </GridContext.Provider>
        </TemplateContext.Provider>
    );
};
