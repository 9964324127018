export const completeUrlFromData = (url, data) => {
  if (!data) {
    return url;
  }
  const urlParts = url.split("/");
  const completedUrlParts = urlParts.map((part) => {
    if (part.startsWith(":")) {
      return data[part.slice(1)];
    }
    return part;
  });
  return completedUrlParts.join("/");
};
