import React from "react";

import dayjs from "dayjs";

import { DatePicker } from "components/DatePicker";
import { FormModal } from "components/FormModal";

export const ReportPeriodModal = ({
  title = "Скачать отчет",
  open,
  handleClose,
  apiUrl,
}) => {
  const today = dayjs();
  const getFields = ({ register, errors, watch, control, dictionaries }) => (
    <>
      <DatePicker
        name={"start_from"}
        control={control}
        label="С"
        maxDate={today}
        slotProps={{
          field: {
            fullWidth: true,
            size: "small",
            error: !!errors.start_from,
            helperText: errors.start_from?.message,
          },
        }}
      />
      <DatePicker
        name={"finish_to"}
        control={control}
        label="По"
        maxDate={today}
        slotProps={{
          field: {
            fullWidth: true,
            size: "small",
            error: !!errors.finish_to,
            helperText: errors.finish_to?.message,
          },
        }}
      />
    </>
  );

  return (
    <FormModal
      title={title}
      open={open}
      handleClose={handleClose}
      apiUrl={apiUrl}
      getFields={getFields}
      submitLabel="Скачать отчет"
    />
  );
};
