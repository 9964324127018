import React from "react";

import {Popover} from "@mui/material";
import {ChatClose} from "templates/Chat/components/ChatClose";

import {ChatPopoverContent} from "components/ChatPopover/ChatPopover.styles";
import {TemplateProvider} from "contexts/TemplateContext";

export const ChatPopover = ({handleClose}) => {
    const anchorEl = document.getElementsByTagName('body')[0];
    return (
        <Popover
            id={'chat_popover'}
            open={true}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <ChatPopoverContent>
                <ChatClose onClose={handleClose}/>
                <TemplateProvider pathOverride='chat' defaultLayout="none"/>
            </ChatPopoverContent>
        </Popover>
    )
}