import {formatBoolean} from "helpers/boolean";
import {formatDate, formatDateTime} from "helpers/dateTime";
import {formatPhone} from "helpers/phone";

export const formatter = (value, format, cleanIfEmpty = false) => {
    if (value == null) {
        return cleanIfEmpty ? '' : "–";
    }
    switch (format) {
        default:
        case "default":
            return value;
        case "datetime":
            return formatDateTime(value);
        case "date":
            return formatDate(value);
        case "money":
            return value + ',00₽';
        case "boolean":
            return formatBoolean(value);
        case "boolean_rev":
            return formatBoolean(!value);
        case "phone":
            return formatPhone(value);
    }
};
