import React from "react";

import {useFieldRules} from "hooks/useFieldRules";

export const withRules = (WrappedComponent) => {
    return function _withRules(props) {
        const cachedRulesProps = useFieldRules(props.name, props.rules);

        if (cachedRulesProps.hidden) {
            return null;
        }

        return <WrappedComponent {...props} {...cachedRulesProps} />;
    };
};
