import {useContext, useEffect, useRef, useState} from "react";

import {useNavigate} from "react-router-dom";
import {
    ChatContentWrap,
    ChatWrap,
    NoConnectionIcon,
    NoConnectionText,
    NoConnectionWrap
} from "templates/Chat/Chat.styles";
import {ChatContent} from "templates/Chat/components/ChatContent";
import {ChatInput} from "templates/Chat/components/ChatInput";
import {ChatLabel} from "templates/Chat/components/ChatLabel";
import {ChatsList} from "templates/Chat/components/ChatsList";

import {ChatContext} from "contexts/ChatContext";
import {SocketContext} from "contexts/SocketContext";
import {useGridApi} from "hooks/useGridApi";

const MESSAGES_PER_PAGE = 20
export const Chat = ({meta: {chats = [], adminMode}, endpoint = 'chat'}) => {
    const messagesEndRef = useRef(null)
    const messagesScrollRef = useRef(null)

    const navigate = useNavigate()

    const {socket, connected} = useContext(SocketContext)
    const {setTotalUnread} = useContext(ChatContext)

    const [chatsUnreadCount, setChatsUnreadCount] = useState(chats?.reduce((acc, {unread_count, id}) => {
        acc[id] = unread_count
        return acc
    }, {}))
    const [activeChatId, setActiveChatId] = useState(chats?.[0]?.id)
    const [prevActiveChatId, setPrevActiveChatId] = useState(null)
    const [scrolledToBeginning, setScrolledToBeginning] = useState(false)
    const [messages, setMessages] = useState([])

    const handleChatMessage = (data) => {
        if (activeChatId !== data.chat_id) {
            if (!data.is_own) {
                setTotalUnread && setTotalUnread(prev => prev + 1)
                setChatsUnreadCount && setChatsUnreadCount(prev => {
                        if (prev[data.chat_id] !== null) {
                            prev[data.chat_id] = prev[data.chat_id] + 1
                        } else if (prev['admin'] !== null) {
                            prev['admin'] = prev['admin'] + 1
                        }

                        return {...prev}
                    }
                )
            }
        } else {
            setMessages(prev => [...prev, {...data, isNew: true}]);
            socket.emit('chat_reset_unread', {chat_id: activeChatId})
        }
    };

    useEffect(() => {
        socket?.on('chat_message', handleChatMessage);

        return () => {
            socket?.off('chat_message', handleChatMessage);
        };
    }, [socket, activeChatId]);

    const {meta, data: loadedMessages, reload, gridControls: {paginationControls}, loading} = useGridApi({
        url: endpoint,
        defaultRowsPerPage: MESSAGES_PER_PAGE,
        requestData: {
            activeChatId,
        },
        defaultSort: [
            {
                id: 'created_at',
                desc: true
            }
        ]
    })

    useEffect(() => {
        if (prevActiveChatId !== null && activeChatId !== prevActiveChatId) {
            setMessages([])
            if (paginationControls.page === 0) {
                reload()
            } else {
                paginationControls.onPageChange(null, 0)
            }
        }
        setPrevActiveChatId(activeChatId)

    }, [activeChatId])


    useEffect(() => {
        if (loadedMessages?.length < MESSAGES_PER_PAGE) {
            setScrolledToBeginning(true)
        } else {
            setScrolledToBeginning(false)
        }

        if (loadedMessages) {
            const newMessagesList = [
                ...loadedMessages,
                ...messages
                    .filter(({id}) => !loadedMessages.find(({id: loadedId}) => loadedId === id))
            ].sort(({created_at: createdA}, {created_at: createdB}) => {
                return createdA > createdB ? 1 : -1
            })

            if (chatsUnreadCount[activeChatId] > 0 && !paginationControls.page) {
                for (let i = 0; i < chatsUnreadCount[activeChatId]; i++) {
                    if (newMessagesList[loadedMessages.length - i - 1]) {
                        newMessagesList[loadedMessages.length - i - 1].isNew = true
                    }
                }
            }

            setMessages(newMessagesList)
        }

    }, [loadedMessages])

    useEffect(() => {
        if (messages.length) {
            let scrollToId

            const wrap = messagesScrollRef.current
            if (paginationControls.page === 0 || wrap.scrollHeight - wrap.scrollTop < wrap.clientHeight * 2) {
                scrollToId = messages[messages.length - chatsUnreadCount[activeChatId] - 1]?.id
            } else {
                scrollToId = messages[loadedMessages.length - 1]?.id
            }

            if (scrollToId) {
                const scrollTo = wrap?.querySelector(`[data-message-id="${scrollToId}"]`)
                scrollTo?.scrollIntoView({behavior: "instant"})
            }


            if (chatsUnreadCount[activeChatId] > 0) {
                socket.emit('chat_reset_unread', {chat_id: activeChatId})
                setChatsUnreadCount(prev => {
                    prev[activeChatId] = 0
                    return {...prev}
                })
            }
        }
    }, [messages])

    const onChatChange = (id) => {
        if (id === 'admin') {
            const chat = chats.find(({id: chatId}) => id === chatId)
            navigate(chat.link)
            return
        }
        setActiveChatId(id)
    }

    const handleScroll = () => {
        const scrollTop = messagesScrollRef.current.scrollTop;


        if (scrollTop === 0 && !scrolledToBeginning && messages.length && !loading) {
            paginationControls?.onPageChange(null, paginationControls.page + 1)
        }
    }

    let currentChat = chats.find(({id}) => id === activeChatId) || null

    if (connected === false) {
        return <NoConnectionWrap pt={2} pr={2}>
            <NoConnectionIcon icon={'wifiOff'}/>
            <NoConnectionText>Ожидание соединения</NoConnectionText>
        </NoConnectionWrap>
    }

    return <ChatWrap>
        {!adminMode && <ChatsList chats={chats} activeChat={activeChatId} onChatChange={onChatChange}
                                  chatsUnreadCount={chatsUnreadCount}/>}
        {!adminMode && <ChatLabel currentChat={currentChat}/>}
        <ChatContentWrap ref={messagesScrollRef} onScroll={handleScroll}>

            <ChatContent messages={messages}
                         loading={loading}/>
            <div ref={messagesEndRef}/>
        </ChatContentWrap>
        {!!currentChat?.allow_input && <ChatInput chat_id={activeChatId}/>}
    </ChatWrap>
}