import React from "react";

import {MenuItem} from "@mui/material";
import {Link} from "react-router-dom";

import {IconComponent} from "components/IconComponent";

export const UserMenuItem = ({icon, label, link, decoration, disabled}) => {
    return <MenuItem component={Link} to={link} disabled={disabled}>
        <IconComponent icon={icon}/>
        &nbsp;{label}
    </MenuItem>
}