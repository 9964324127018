import {ListItem} from "@mui/material";
import styled from "styled-components";

export const StyledListItem = styled(ListItem)`
  &.MuiListItem-root[disabled] {
    opacity: 1;
  }

  &[disabled] a {
    cursor: default;
    pointer-events: none;
  }

  &[disabled] a > *:not([data-decorator]) {
    opacity: 0.5;
  }
`