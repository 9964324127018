import MenuIcon from "@mui/icons-material/Menu";
import {AppBar, Box} from "@mui/material";
import styled from "styled-components";

export const TopBarStyled = styled(AppBar)`
`;

export const BarLeftGroup = styled(Box)`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const MenuIconStyled = styled(MenuIcon)`
  color: ${(p) => p.theme.palette.common.white};
`;

export const UserBlockWrap = styled(Box)`
  cursor: pointer;
`;
