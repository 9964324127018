import React, { useState } from "react";

import { debounce } from "debounce";
import AsyncSelect from "react-select/async";

import { SelectLabel } from "components/SelectLabel";
import { useDictApiAsync } from "hooks/useDictApiAsync";

export const ReactSelectAddress = ({
  remoteDict,
  onChange,
  label,
  onBlur,
  onFocus,
  value,
  required,
  ...rest
}) => {
  const [searchActive, setSearchActive] = useState(false);
  const [focused, setFocused] = useState(false);

  const { requestAsync } = useDictApiAsync({
    remoteDict,
  });

  const loadOptions = (search, callback) => {
    if (search.length < 3) {
      setSearchActive(false);
      callback([]);
    }
    if (focused) setSearchActive(true);
    requestAsync({ search }).then((data) => callback(data));
  };

  const loadOptionsDebounced = debounce(loadOptions, 1000);

  const noOptionsMessage = (s) =>
    s.inputValue.length > 3 ? (
      <>
        Не удалось найти <b>{s.inputValue}</b>
      </>
    ) : (
      "Введите адрес"
    );

  const selectStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontWeight: data.is_popular ? "bold" : "normal",
      };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const handleChange = (val) => {
    setSearchActive(false);
    onChange(val.value);
  };

  const components = {
    DropdownIndicator: () => <></>,
    IndicatorSeparator: () => <></>,
  };

  const handleBlur = () => {
    onBlur();
    setFocused(false);
    setSearchActive(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const displayValue = { value: value ? value : "", label: value ? value : "" };

  const loadingMessage = ({ inputValue }) => {
    return "Загрузка...";
  };

  return (
    <>
      <SelectLabel shrink focused={focused} required={required}>
        {label}
      </SelectLabel>
      <AsyncSelect
        placeholder={label}
        isAsync={true}
        loadOptions={loadOptionsDebounced}
        noOptionsMessage={noOptionsMessage}
        styles={selectStyles}
        onChange={handleChange}
        menuIsOpen={searchActive}
        components={components}
        menuPortalTarget={document.body}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={displayValue}
        loadingMessage={loadingMessage}
        {...rest}
      />
    </>
  );
};
