import {blue} from "@mui/material/colors";
import styled from "styled-components";

export const StyledIconWrap = styled.div`
  cursor: help;
  background-color: ${p => p.theme.palette.primary.main};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.palette.primary.contrastText};
  padding: 1px;
`

export const TitleWrap = styled.div`
  a {
    color: ${p => blue[200]};
  }
`