import React from "react";

import { CircularProgress } from "@mui/material";

import { ProgressWrapper } from "components/ApiForm/components/SubmitCircularLoader.styles";

export const SubmitCircularLoader = () => {
  return (
    <ProgressWrapper>
      <CircularProgress size={20} sx={{ color: "grey.600" }} />
    </ProgressWrapper>
  );
};
