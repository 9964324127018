import {useMemo} from "react";

import {Box, Grid} from "@mui/material";

import {ActionGroup, prepareAgActions} from "components/ActionGroup";
import {ResolvedTemplate} from "components/TemplateResolver/ResolvedTemplate";

export const Composition = ({data, meta}) => {
    const actions = prepareAgActions(meta?.headerActions);
    const {positioning, spacing = 2} = meta;

    const templateSmWidth = useMemo(() => {
        if (positioning === "vertical") {
            return 12
        }
        return 6
    }, [positioning]);

    return (
        <div>
            {!!actions.length && <Box pb={2}>
                <ActionGroup buttons={actions}/>
            </Box>}

            <Grid container spacing={spacing} pb={2}>
                {data.map((template) => {
                    return (
                        <Grid item xs={12} sm={templateSmWidth} key={template.meta.key}>
                            <ResolvedTemplate {...template} />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};
