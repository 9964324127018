import React from "react";

import {Box, Grid, Tooltip, Typography} from "@mui/material";
import * as PropTypes from "prop-types";
import {WidgetDecorator} from "templates/Dashboard/components/ModelWidget/WidgetDecorator";

import {ActionGroup} from "components/ActionGroup";
import {IconComponent} from "components/IconComponent";

import {
    ActionsWrap,
    GridLinkStyled,
    HelpIconWrap,
    IconContainer,
    StatisticWrap,
    StyledErrorIcon,
    StyledHelpIcon,
    StyledMenuHeader,
    StyledPaper,
    TopIconsBar,
    WarningIconWrap,
} from "./ModelWidget.styles";

StatisticWrap.propTypes = {children: PropTypes.node};

const ModelWidget = ({
                         icon,
                         to,
                         label,
                         stat = [],
                         warning,
                         actions = [],
                         tooltipText,
                         target,
                         disabled,
                         decoration,
                     }) => {
    return (
        <Grid item xs={6} md={4} lg={3}>
            <StyledPaper component={Box} py={1} px={1} disabled={disabled}>
                <TopIconsBar>
                    {tooltipText && (
                        <HelpIconWrap>
                            <Tooltip
                                title={<Typography fontSize={14}>{tooltipText}</Typography>}
                            >
                                <StyledHelpIcon/>
                            </Tooltip>
                        </HelpIconWrap>
                    )}
                    {!!warning && (
                        <WarningIconWrap>
                            <Tooltip
                                title={<Typography fontSize={14}>{warning.text}</Typography>}
                            >
                                <StyledErrorIcon color={warning.color}/>
                            </Tooltip>
                        </WarningIconWrap>
                    )}
                </TopIconsBar>
                <GridLinkStyled to={to} target={target}>
                    <IconContainer>
                        <IconComponent icon={icon}/>
                    </IconContainer>
                    <StyledMenuHeader variant={"h6"}>{label}</StyledMenuHeader>
                    <StatisticWrap>
                        {Object.values(stat).map(({label, value}) => (
                            <li key={label}>
                                {label}: <b>{value}</b>
                            </li>
                        ))}
                    </StatisticWrap>
                </GridLinkStyled>
                <ActionsWrap>
                    <ActionGroup buttons={actions}/>
                </ActionsWrap>
                {<WidgetDecorator decoration={decoration}/>}
            </StyledPaper>

        </Grid>
    );
};

export default ModelWidget;
