import React from "react";

import {Box, Button} from "@mui/material";
import Cookies from "js-cookie";
import {toast} from "react-toastify";

import {CleanLink} from "components/CleanLink";

let approveShow = 0

export const useLegalApprove = ({data}) => {
    if (!Cookies.get('LEGAL_APPROVED') && data?.legal_document_link && !approveShow) {
        approveShow = 1
        const msg = <>
            <Box py={1}>
                Мы используем Cookie, чтобы могли улучшать наши продукты, веб-сайт, делать их более удобными для Вас и
                делать самые выгодные предложения.
            </Box>
            <CleanLink to={data?.legal_document_link}>Подробнее об условиях использования</CleanLink>
            <Box py={1} display="flex" justifyContent="right">
                <Button variant="outlined" color="success">Ок</Button>
            </Box>
        </>
        toast.info(msg, {
            autoClose: false,
            onClose: () => {
                Cookies.set('LEGAL_APPROVED', true)
            }
        });
    }
}