import {Box, Typography} from "@mui/material";
import {markupParser} from "helpers/markupParser";
import {fillTemplate} from "helpers/templateFiller";

export const EventMessage = ({
                                 eventType,
                                 data,
                                 relatedType,
                                 relatedId,
                                 showRecordLabel = false,
                                 modelsLogMeta,
                             }) => {

    const {layout, label, template} = modelsLogMeta?.[relatedType]?.['templates']?.[eventType] || {};
    const {fields} = modelsLogMeta?.[relatedType] || {};

    switch (layout) {
        case 'plain':
            return (
                <Box component="ul" p={1} pb={2} m={0}>
                    {showRecordLabel && (
                        <b>{markupParser(fillTemplate(label, data))}</b>
                    )}
                    <div>{markupParser(fillTemplate(template, data))}</div>
                </Box>
            );
        case 'changelist':
            return (
                <Box component="ul" p={1} pb={2} m={0}>
                    {showRecordLabel && (
                        <b>{markupParser(fillTemplate(label, data))}</b>
                    )}
                    {Object.keys(data).map((key) => {
                        const keyData = data[key] || [];
                        const {from = "–", to = "–"} = keyData;
                        return (
                            <div key={key}>
                                <Typography component='span' fontWeight={500}>{fields?.[key] || key}</Typography>:{" "}
                                {from} <Typography component='span' fontWeight={500}>{from && "> "}</Typography>
                                {to}
                            </div>
                        );
                    })}
                </Box>
            );
            break;
        default:
            return <>сделал изменения</>;
    }
};
