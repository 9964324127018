import {TextField} from "@mui/material";
import styled from "styled-components";

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    background-color: white;
  }

  &[hideArrows] {
    /* Chrome, Safari, Edge, Opera */

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */

    input[type=number] {
      -moz-appearance: textfield;
    }
  }
`;
