import {makeApiRequest} from "helpers/makeApiRequest";

import {ConfirmationModal} from "components/ConfirmationModal";
import {FormModal} from "components/FormModal";
import {ReportPeriodModal} from "components/ReportPeriodModal";

// TODO: make all custom actions inherit from basic actions
const agActionFormatter = (action) => {
    const {type, data} = action;
    switch (type) {
        // Basic
        case "form":
            return agForm(data);
        case "request":
            return agRequest(data);
        case "link":
            return agLink(data);
        case "export":
            return agExport(data);
        // Custom
        case "report":
            return agDownloadReport(data);
        case "block":
            return agBlock(data);
        case "unblock":
            return agUnblock(data);
        case "add":
            return agAdd(data);
    }
};

export const prepareAgActions = (actions = []) => {
    return actions.map(agActionFormatter);
};

export const agBasic = (props) => ({
    color: "success",
    ...props,
});

export const agLink = ({url, ...data}) => {
    const onClick = (_e, {navigate}) => {
        const isAbsoluteUrl = url.startsWith("http");
        if (isAbsoluteUrl) {
            window.open(url, "_blank");
            return;
        }
        navigate(url);
    };

    return {
        ...data,
        onClick,
    };
};

export const agForm = (data) => {
    const onClick = (_e, {openModal, reload}) => {
        openModal({
            component: FormModal,
            apiUrl: data.url,
            onSuccess: () => {
                reload();
            },
        });
    };
    return {
        color: data.color || "primary",
        icon: data.icon,
        label: data.label,
        onClick,
    };
};

export const agAdd = ({
                          color = "success",
                          icon = "add",
                          label = "Добавить",
                          ...otherData
                      }) =>
    agLink({
        color,
        icon,
        label,
        ...otherData,
    });

// TODO: replace with agForm?
export const agDownloadReport = (data, openModal) => {
    const onClick = (_e, {openModal, reload}) => {
        openModal({
            component: ReportPeriodModal,
            apiUrl: data.url,
        });
    };
    return {
        color: "primary",
        icon: "fileDownload",
        label: "Скачать отчет",
        onClick,
    };
};

// TODO: basic parent action: confirm => post request => reload
export const agBlock = (data) => {
    const onConfirm = (reload) => () => {
        makeApiRequest({
            config: {
                url: data.url,
                method: "post",
            },
        }).then(() => {
            reload();
        });
    };
    const onClick = (_e, {openModal, reload}) => {
        openModal({
            component: ConfirmationModal,
            onConfirm: onConfirm(reload),
            title: "Вы действительно хотите заблокировать пользователя?",
            submitButtonText: "Заблокировать",
        });
    };

    return {
        color: "error",
        icon: "lock",
        label: "Заблокировать",
        onClick,
    };
};

export const agUnblock = (data) => {
    const onConfirm = (reload) => () => {
        makeApiRequest({
            config: {
                url: data.url,
                method: "post",
            },
        }).then(() => {
            reload();
        });
    };
    const onClick = (_e, {openModal, reload}) => {
        openModal({
            component: ConfirmationModal,
            onConfirm: onConfirm(reload),
            title: "Вы действительно хотите разблокировать пользователя?",
            submitButtonText: "Разблокировать",
        });
    };

    return {
        color: "warning",
        icon: "lockReset",
        label: "Разблокировать",
        onClick,
    };
};

export const agRequest = ({data, url, ...otherData}) => {
    const onClick = (_e, {reload}) => {
        makeApiRequest({
            config: {
                url,
                method: "post",
                data,
            },
        }).then(() => {
            reload();
        });
    };
    return {
        onClick: onClick,
        ...otherData,
    };
};

export const agExport = (data) => {
    const onClick = (_e, {dataExport}) => {
        dataExport(`export.csv`);
    };

    return {
        onClick,
        ...data,
    };
};
