import {useCallback, useEffect, useMemo, useState} from "react";

import {useApi} from "./useApi";

export const useGridApi = ({initialMethod = "post", defaultSort = [], defaultRowsPerPage = 20, ...props}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [sort, setSort] = useState(defaultSort);
    const [activeFilters, setActiveFilters] = useState({});

    const apiHandler = useApi({
        ...props,
        initialMethod,
        disableInitialLoad: true,
        requestData: {
            ...props.requestData,
            pagination: {
                page,
                rows_per_page: rowsPerPage,
            },
            sort,
            filters: activeFilters,
        },
    });

    const checkFiltersIntegrity = () => {
        let integrity = true;
        Object.keys(activeFilters).map((columnKey) => {
            Object.keys(activeFilters[columnKey]).map((filterKey) => {
                if (Array.isArray(activeFilters[columnKey][filterKey])) {
                    const allowedValues = apiHandler.meta.filters[columnKey][
                        filterKey
                        ].dictionary.map(({value}) => String(value));

                    if (
                        activeFilters[columnKey][filterKey].some(
                            (value) => !allowedValues.includes(String(value))
                        )
                    ) {
                        updateFilterState(
                            columnKey,
                            filterKey,
                            activeFilters[columnKey][filterKey].filter((value) =>
                                allowedValues.includes(value)
                            )
                        );
                        integrity = false;
                    }
                }
            });
        });
        return integrity;
    };

    useEffect(() => {
        if (checkFiltersIntegrity()) {
            apiHandler.reload();
        }
    }, [page, rowsPerPage, sort, activeFilters]);

    const onPageChange = useCallback(
        (_e, page) => {
            setPage(page);
        },
        [setPage]
    );

    const onRowsPerPageChange = useCallback(
        (e, el) => {
            setPage(0);
            setRowsPerPage(el.props.value);
        },
        [setRowsPerPage]
    );

    const paginationControls = {
        count: apiHandler.meta?.pagination?.count || 0,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
    };

    const sortControls = useMemo(
        () => ({
            sort,
            onSort: setSort,
            sortable: apiHandler.meta?.sortable || [],
        }),
        [sort, setSort, apiHandler.meta?.sortable]
    );

    const onFilterChange = (column) => (index) => (value) => {
        updateFilterState(column, index, value);
    };

    const filterControls = {
        filters: apiHandler.meta?.filters || {},
        activeFilters,
        onFilterChange,
    };

    const updateFilterState = (column, index, value) => {
        const defaultValue = apiHandler.meta?.filters[column][index].default_value;
        setActiveFilters((prevActiveFilters) => {
            const updatedState = {...prevActiveFilters};
            if (!updatedState[column]) {
                updatedState[column] = {};
            }

            let isEqual = false;
            if (Array.isArray(value) && Array.isArray(defaultValue)) {
                isEqual =
                    value.length === defaultValue.length &&
                    defaultValue.every((v) => value.includes(v));
            } else {
                isEqual = defaultValue === value;
            }

            if (isEqual) {
                delete updatedState[column][index];
            } else {
                updatedState[column][index] = value;
            }

            if (!Object.keys(updatedState[column]).length) {
                delete updatedState[column];
            }

            return updatedState;
        });
    };

    const gridControls = {
        paginationControls,
        sortControls,
        filterControls,
        dataExport: apiHandler.dataExport,
        loading: apiHandler.loading,
    };

    return {...apiHandler, gridControls};
};
