import {Box} from "@mui/material";
import styled from "styled-components";

export const StyledDevOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  div {
    font-size: 1.5em;
    color: red;
    font-weight: 600;
    background: #fff;
    opacity: 0.8;
    border: 2px solid red;
    padding: 0.5em;
    text-transform: uppercase;
    rotate: -30deg;
  }
`

export const CornerSignOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 4em;
  height: 4em;
  background: linear-gradient(-225deg, ${p => p.theme.palette.primary.contrastText} 50%, transparent 50%);

  div {
    color: ${p => p.theme.palette.primary};
    font-size: 1.5em;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
    padding-left: 0.4em;
    padding-bottom: 0.6em;
  }
`

export const NegativeBalanceOverlay = styled(CornerSignOverlay)`
  background: linear-gradient(-225deg, ${p => p.theme.palette.error.main} 50%, transparent 50%);

  div {
    color: white;
  }
`

export const PaidPlanRequiredOverlay = styled(CornerSignOverlay)``;