import {useState} from "react";

import {makeApiRequest} from "helpers/makeApiRequest";

export const useUpload = (props = {}) => {
    const [loading, setLoading] = useState(false);
    const {
        url = 'uploads/upload',
        uploadTemplate = 'document',
    } = props
    const upload = (files) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', files[0]);
        return makeApiRequest({
            config: {
                url: uploadTemplate ? url + '/' + uploadTemplate : url,
                data: formData,
                method: 'POST',
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return {upload, loading};
}