import {Box} from "@mui/material";
import styled from "styled-components";

export const ChatInputStyled = styled(Box)`
  padding-top: 1em;

  & form .MuiFormControl-root, & form .MuiTextField-root, & form .MuiButtonBase-root {
    margin: 0;
  }

  & form .MuiInputBase-root {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & form .MuiButtonBase-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`