import { Box } from "@mui/material";
import styled from "styled-components";

export const FormContainerStyled = styled(Box)`
  display: flex;
  flex-direction: column;

  & .MuiTextField-root,
  & .MuiButtonBase-root,
  & .MuiFormControl-root {
    margin: 0.5em 0;
  }
`;
