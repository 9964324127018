import { useApi } from "./useApi";

export const useDictApiAsync = ({
  remoteDict,
  disableInitialLoad = true,
  ...props
}) => {
  const apiHandler = useApi({
    ...props,
    url: `dict/${remoteDict}`,
    initialMethod: "post",
    disableInitialLoad: true,
    responseType: "json",
  });

  const requestAsync = (dependencies) => {
    return apiHandler.onSubmit({ dependencies }).then(({ data }) => data);
  };

  return { requestAsync };
};
