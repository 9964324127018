export const formatPhone = (raw) => {
    if (!raw) return "-";
    return (
        "+" +
        raw.substring(0, 1) +
        " (" +
        raw.substring(1, 4) +
        ") " +
        raw.substring(4, 7) +
        " " +
        raw.substring(7, 9) +
        " " +
        raw.substring(9)
    );
};
