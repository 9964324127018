import {
    Add,
    Archive,
    ArrowForward,
    Article,
    Badge,
    Balance,
    CameraFront,
    CellTower,
    Chat,
    Close,
    ContactSupport,
    ContentPaste,
    CurrencyRuble,
    Dangerous,
    Delete,
    DirectionsRun,
    Edit,
    EmojiTransportation,
    FileDownload,
    Flag,
    Grade,
    Grading,
    LocalShipping,
    LocalTaxi,
    Lock,
    LockReset,
    ManageAccounts,
    Notifications,
    PauseCircle,
    PeopleAlt,
    Person,
    PlayCircle,
    PointOfSale,
    PublishedWithChanges,
    QuestionMark,
    Remove,
    SafetyCheck,
    Savings,
    Settings,
    Timeline,
    Widgets,
    WifiOff,
} from "@mui/icons-material";

const importedIcons = {
    add: Add,
    arrowForward: ArrowForward,
    widgets: Widgets,
    localShipping: LocalShipping,
    settings: Settings,
    person: Person,
    grade: Grade,
    article: Article,
    fileDownload: FileDownload,
    lock: Lock,
    lockReset: LockReset,
    localTaxi: LocalTaxi,
    questionMark: QuestionMark,
    peopleAlt: PeopleAlt,
    timeline: Timeline,
    pointOfSale: PointOfSale,
    contentPaste: ContentPaste,
    edit: Edit,
    archive: Archive,
    playCircle: PlayCircle,
    pauseCircle: PauseCircle,
    dangerousIcon: Dangerous,
    grading: Grading,
    remove: Remove,
    delete: Delete,
    publishedWithChanges: PublishedWithChanges,
    savings: Savings,
    balance: Balance,
    safetyCheck: SafetyCheck,
    badge: Badge,
    contactSupport: ContactSupport,
    cameraFront: CameraFront,
    currencyRuble: CurrencyRuble,
    directionsRun: DirectionsRun,
    flag: Flag,
    chat: Chat,
    close: Close,
    notifications: Notifications,
    manageAccounts: ManageAccounts,
    wifiOff: WifiOff,
    cellTower: CellTower,
    emojiTransportation: EmojiTransportation,
};

const resolveIcon = (icon) => {
    return importedIcons[icon] ?? importedIcons["questionMark"];
};
export const IconComponent = ({icon, ...rest}) => {
    let iconName = "";
    if (typeof icon === "object") {
        rest.color = icon.color;
        iconName = icon.icon;
    } else {
        iconName = icon;
    }
    const IconComponent = resolveIcon(iconName);
    return <IconComponent {...rest} />;
};
