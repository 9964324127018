import { Fragment, useContext } from "react";

import { Box, Typography } from "@mui/material";
import { ensureArrayWrap } from "helpers/array";
import { markupParser } from "helpers/markupParser";

import { FooterLineWrap } from "components/Footer/Footer.styles";
import { AppContext } from "contexts/AppContext";

export const Footer = () => {
  const { footer = [] } = useContext(AppContext);

  return (
    <div>
      <Typography component={Box}>
        {ensureArrayWrap(footer).map((line, i) => (
          <FooterLineWrap key={i}>
            {ensureArrayWrap(line).map((lineItem, j) => (
              <Fragment key={j}>
                {markupParser(lineItem)}
                {j === line.length - 1 ? (
                  ""
                ) : (
                  <Box component="span" px={1}>
                    |
                  </Box>
                )}
              </Fragment>
            ))}
          </FooterLineWrap>
        ))}
      </Typography>
    </div>
  );
};
