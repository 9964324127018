import {
  NoDataWrapper,
  StyledTypography,
} from "components/Table/components/NoData.styles";

export const NoData = ({ loading }) => {
  return (
    <tbody>
      <tr>
        <td colSpan="100%">
          <NoDataWrapper>
            <div>
              <StyledTypography fontWeight="bold">
                {loading ? "Загрузка" : "Нет данных"}
              </StyledTypography>
            </div>
          </NoDataWrapper>
        </td>
      </tr>
    </tbody>
  );
};
