import {useMemo} from "react";

import {Grid} from "@mui/material";

import {ApiForm} from "components/ApiForm";

export const Form = ({data, meta, endpoint, onSuccess}) => {
    const {cleanOnSuccess, fields} = meta;

    const lgSize = useMemo(() => {
        if (
            meta.layout === "modal" ||
            meta.layout === "auth" ||
            meta.key !== "main"
        ) {
            return 12;
        }

        return 6;
    }, [meta.key, meta.layout]);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={lgSize}>
                    <ApiForm
                        fields={fields}
                        apiUrl={meta.submitUrl || endpoint}
                        cleanOnSuccess={cleanOnSuccess}
                        disableInitialLoad={true}
                        onSuccess={onSuccess}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
