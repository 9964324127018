import {saveAs} from "file-saver";
import Cookies from "js-cookie";
import {toast} from "react-toastify";

const {REACT_APP_API_URL} = process.env;


export const makeApiRequest = ({config: {fileDownloadName, ...config}}, retryCount = 0) => {
    let url = `${REACT_APP_API_URL || "api/"}${config.url}`;

    const headers = {
        'Accept': 'application/json',
        'X-CSRFTOKEN': Cookies.get('csrftoken'),
        ...config.headers,
    }

    const requestInit = {
        method: config.method || 'GET',
        credentials: 'include',
        redirect: 'manual',
        headers,
    };

    if (config.method.toUpperCase() === 'GET') {
        // const queryParams = new URLSearchParams(config.data);
        // url += `?${queryParams.toString()}`;
    } else if (config.data) {
        if (config.data instanceof FormData) {
            requestInit.body = config.data;
        } else {
            requestInit.headers['Content-Type'] = 'application/json';
            requestInit.body = JSON.stringify(config.data);
        }
    }

    return fetch(url, requestInit)
        .catch((e) => {
            if (e.response?.status === 403) {
            } else if (e.response?.status === 404) {
            } else if (e.response?.status === 400) {
                if (retryCount === 0) {
                    return makeApiRequest({config: {url: "app"}}).then(() => {
                        return makeApiRequest({config}, 1);
                    });
                }
            } else {
                console.log(e);
                toast.error("Ошибка ответа сервера");
            }
        }).then(async (response) => {
            if (retryCount > 0) {
                return response
            }
            if (!response) {
                return;
            }
            const {status, headers} = response;

            if (response.type === 'opaqueredirect') {
                window.location.href = response.url;
            }

            if (status === 200) {
                const contentDisposition = headers.get('content-disposition');

                if (contentDisposition && contentDisposition.startsWith('attachment')) {
                    const fileName = contentDisposition
                        .split(';')
                        .find(part => part.trim().startsWith('filename='))
                        .split('=')[1]
                        .trim()
                        .replace(/"/g, '');

                    return response.blob().then(blob => {
                        saveAs(blob, fileName);
                        return 1;
                    });
                } else {
                    return response.json().then(data => {
                        const {messages} = data;
                        if (messages) {
                            messages.forEach(({text, type}) => {
                                if (!["info", "success", "warning", "error"].includes(type))
                                    type = "info";
                                toast[type](text);
                            });
                        }
                        return data;
                    });
                }
            }
            return response;
        });
};
