import React, {useContext} from "react";

import {AccountCircle} from "@mui/icons-material";
import {IconButton, Toolbar, Typography} from "@mui/material";

import {CleanLink} from "components/CleanLink";
import {AppContext} from "contexts/AppContext";
import {UserMenu} from "layouts/components/UserMenu";

import {BarLeftGroup, MenuIconStyled, TopBarStyled, UserBlockWrap} from "./TopBar.styles";

const TopBar = ({toggleDrawer}) => {
    const {appName} = useContext(AppContext);

    const userIconRef = React.useRef(null);

    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);

    const userMenuClick = (event) => {
        event.preventDefault()
        setUserMenuAnchorEl(userIconRef.current);
    };

    const handleCloseUserMenu = () => {
        setUserMenuAnchorEl(null);
    };

    const {user} = useContext(AppContext);

    return (
        <TopBarStyled position="static">
            <Toolbar>
                <BarLeftGroup>
                    <IconButton onClick={toggleDrawer}>
                        <MenuIconStyled/>
                    </IconButton>
                    <CleanLink to="/">
                        <Typography variant="h6" component="div" color='primary.contrastText'>
                            {appName}
                        </Typography>
                    </CleanLink>
                </BarLeftGroup>
                <UserBlockWrap onClick={userMenuClick}>
                    <b>{user.email}</b>
                    <IconButton
                        size="large"
                        color="inherit"
                        ref={userIconRef}
                    >
                        <AccountCircle/>
                    </IconButton>
                </UserBlockWrap>
                <UserMenu
                    anchorEl={userMenuAnchorEl}
                    handleClose={handleCloseUserMenu}
                />
            </Toolbar>
        </TopBarStyled>
    );
};

export default TopBar;
