import {Tooltip} from "@mui/material";
import Badge from "@mui/material/Badge";
import {ChatsListWrap, StyledIconButton} from "templates/Chat/components/ChatsList.styles";

import {IconComponent} from "components/IconComponent";

export const ChatsList = ({chats, onChatChange, activeChat, chatsUnreadCount}) => {
    return <ChatsListWrap>
        {chats.map((chat) => <Tooltip title={chat.title} key={chat.id}>
            <Badge badgeContent={chatsUnreadCount[chat.id]} color="error">
                <StyledIconButton data-active={activeChat === chat.id} onClick={() => onChatChange(chat.id)}>
                    <IconComponent icon={chat.icon}/>
                </StyledIconButton>
            </Badge>
        </Tooltip>)}
    </ChatsListWrap>
}