import { useCallback, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { debounce } from "debounce";

export const TextFilter = ({
  onChange,
  value: initialValue,
  label,
  autoFocus,
}) => {
  const [value, setValue] = useState(initialValue || "");
  const onChangeDebounced = useCallback(debounce(onChange, 500), []);

  const handleChange = (e) => {
    setValue(e.target.value);
    return onChangeDebounced(e.target.value);
  };

  const clear = () => {
    setValue("");
    onChange("");
  };

  return (
    <TextField
      label={label}
      onChange={handleChange}
      value={value}
      size="small"
      pr={0}
      autoFocus={autoFocus}
      fullWidth
      InputProps={{
        style: { paddingRight: 0 },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={clear}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
