import {createColumnHelper} from "@tanstack/react-table";
import {formatter} from "helpers/formatter";

import {
    apiRequestAction,
    editAction,
    formAction,
    linkAction,
    viewAction,
    watchNoteAction,
    withButtonRules,
} from "components/Table/components/Actions/helpers";
import {TableRowActions} from "components/Table/index";

const columnFormatter = (format) => {
    return (props) => {
        return formatter(props.getValue(), format);
    };
};

export const actionFormatter = (originalRow) => (action) => {
    const {type, data} = action;
    switch (type) {
        case "note":
            return watchNoteAction(originalRow, data);
        case "view":
            return viewAction(data.url + originalRow.id);
        case "edit":
            return editAction(data.url + originalRow.id);
        case "postRequest": // TODO: remove view and edit actions
        case "apiRequest": // TODO: replace postRequest with general request
            return apiRequestAction(originalRow, data);
        case "link":
            return linkAction(originalRow, data);
        case "form":
            return formAction(originalRow, data);
        case "dropdown":
            return dropdownAction(originalRow, data);
    }
};

export const prepareColumns = (columns = []) => {
    const columnHelper = createColumnHelper();
    return columns.map(({type, name, header, formatter, size, ...other}) => {
        switch (type) {
            default:
            case "accessor":
                return columnHelper.accessor(name, {
                    header,
                    cell: columnFormatter(formatter),
                    size: size,
                });
            case "actions":
                const {actions} = other;
                return columnHelper.display({
                    header,
                    size,
                    cell: ({row: {original}}) => {
                        const rowActions = actions
                            .map(actionFormatter(original))
                            .filter((action) => action !== null);
                        return <TableRowActions actions={rowActions}/>;
                    },
                });
        }
    });
};

export const applyColorRules = (colorRules = [], row) => {
    let preparedStyle = {};
    colorRules.forEach((colorRule) => {
        const {field, condition = "is", value, style} = colorRule;
        if (condition === "is") {
            if (row[field] === value) {
                preparedStyle = {...preparedStyle, ...style};
            }
        }
    });
    return preparedStyle;
};

export const dropdownAction = (originalRow, {url, icon, label, rules = [], title, items: rawItems}) => {
    const onClick = (_e, {openModal, reloadGridData, navigate, reloadTemplate, openItems}) => {
        openItems()
    }

    const items = rawItems.map(item => {
        return actionFormatter(originalRow)(item)
    })

    return withButtonRules(
        {rules, data: originalRow},
        {label, onClick, icon, items}
    );
}
