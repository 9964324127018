import styled from "@emotion/styled";
import {IconButton} from "@mui/material";
import Badge from '@mui/material/Badge';

import {IconComponent} from "components/IconComponent";


export const ChatIconWrap = styled.div`
  position: fixed;
  bottom: 3em;
  right: 3em;
`

export const StyledBadge = styled(Badge)`
  margin: 0.4em;
`

export const StyledIconButton = styled(IconButton)`
  padding: 0.5em;
  background-color: ${p => p.theme.palette.primary.main};
  color: ${p => p.theme.palette.primary.contrastText};

  &:hover {
    background-color: ${p => p.theme.palette.primary.dark};
  }
`

export const StyledIconComponent = styled(IconComponent)`
  font-size: 2em;
`
