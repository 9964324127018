import React from "react";

import {Controller, useFormContext} from "react-hook-form";
import InputMask from "react-input-mask";
import {withRules} from "templates/Form/withRules";

import {TextFieldHelpInfo} from "components/TextInput/TextFieldHelpInfo";
import {StyledTextField} from "components/TextInput/TextInput.styles";
import {InputAdornment} from "@mui/material";

const TextInputComponent = ({
                                name,
                                size = "small",
                                helperText,
                                mask,
                                value,
                                rules,
                                maxLength,
                                info,
                                submitOnEnter,
                                startAdornment,
                                ...props
                            }) => {
    const {
        onSubmit,
        control,
        formState: {errors: formErrors},
    } = useFormContext();

    const errors = formErrors?.[name];
    const error = !!errors;
    const fieldHelperText = error ? errors?.message : helperText;

    const endAdornment = info ? <TextFieldHelpInfo info={info}/> : null;
    const startAdornmentFormatted = startAdornment ?
        <InputAdornment position="start">{startAdornment}</InputAdornment> : null;

    const handleKeyPress = (e) => {
        if (submitOnEnter && e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
            e.preventDefault();
            onSubmit()
        }
    }

    const getInputField = ({value: fieldVal, onChange: fieldOnChange, ...rest}) => {
        const value = fieldVal != null ? fieldVal : "";
        const onChange = (e) => {
            const val = String(e.target.value || '');
            if (maxLength && val.length > maxLength) {
                return;
            }
            return fieldOnChange(e);
        }
        if (mask) {
            return (
                <InputMask mask={mask} value={value} maskChar="_" onChange={onChange} {...rest}>
                    {() => (
                        <StyledTextField
                            error={error}
                            helperText={fieldHelperText}
                            size={size}

                            {...props}
                            InputProps={{
                                endAdornment: endAdornment,
                                startAdornment: startAdornmentFormatted,
                                onKeyDown: handleKeyPress,
                                ...props.InputProps,
                            }}
                        />
                    )}
                </InputMask>
            );
        }
        return (
            <StyledTextField
                value={value}
                error={error}
                helperText={fieldHelperText}
                size={size}
                onChange={onChange}
                {...props}
                {...rest}
                InputProps={{
                    endAdornment: endAdornment,
                    startAdornment: startAdornmentFormatted,
                    onKeyDown: handleKeyPress,
                    ...props.InputProps,
                    ...rest.InputProps,
                }}
            />
        );
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({field}) => getInputField(field)}
            defaultValue={value}
        />
    );
};

export const TextInput = withRules(TextInputComponent);
