import React, {useEffect} from "react";

import Select from "react-select";

import {SelectLabel} from "components/SelectLabel";

export const ReactSelect = ({
                                items = [],
                                label,
                                placeholder,
                                isMulti,
                                value,
                                onChange,
                                ...rest
                            }) => {
    const options = items.map(({name, value, sublabel = '', is_popular = false}) => ({
        label: name,
        value: String(value),
        sublabel: sublabel != null ? String(sublabel) : null,
        is_popular,
    }));

    useEffect(() => {
        if ((isMulti && value.length) || (!isMulti && value != null)) {
            const filteredValue = filterAllowed(value);

            if (
                (isMulti && value.length !== filteredValue.length) ||
                (!isMulti && value !== filteredValue)
            ) {
                onChange(filteredValue);
            }
        }
    }, [options]);

    const displayValue =
        (isMulti
            ? options.filter(({value: optVal}) =>
                value.map(String).includes(optVal)
            )
            : options.find(({value: optVal}) => String(value) === optVal)) ?? null;

    const noOptionsMessage = (s) =>
        s.inputValue.length ? (
            <>
                Не удалось найти <b>{s.inputValue}</b>
            </>
        ) : (
            "Список пуст"
        );

    const filterAllowed = (selected) => {
        const allowedValues = options.map(({value}) => value);
        if (isMulti) {
            return selected.filter((newVal) =>
                allowedValues.includes(String(newVal))
            );
        }

        if (allowedValues.includes(String(selected))) {
            return String(selected);
        }

        return null;
    };

    const handleChange = (val) => {
        onChange(
            filterAllowed(isMulti ? val.map(({value}) => value) : val.value)
        );
    };

    const selectStyles = {
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                fontWeight: data.is_popular ? "bold" : "normal",
            };
        },
        menuPortal: (base) => ({...base, zIndex: 9999}),
    };

    return (
        <>
            <SelectLabel shrink>{label}</SelectLabel>
            <Select
                placeholder={placeholder}
                options={options}
                value={displayValue}
                menuPortalTarget={document.body}
                isMulti={isMulti}
                noOptionsMessage={noOptionsMessage}
                onChange={handleChange}
                styles={selectStyles}
                minChars={1}
                menuPlacement='auto'
                {...rest}
            />
        </>
    );
};
