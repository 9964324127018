import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import {completeUrlFromData} from "helpers/url";

import {ConfirmationModal} from "components/ConfirmationModal";
import {FormModal} from "components/FormModal";
import {NotesModal} from "components/NotesModal";

import {EditIconStyled, VisibilityIconStyled,} from "./StyledActionIcons";
import {makeApiRequest} from "helpers/makeApiRequest";

export const editAction = (editUrl) => ({
    title: "Редактировать",
    link: editUrl,
    icon: EditIconStyled,
});

export const viewAction = (viewUrl) => ({
    title: "Просмотреть",
    link: viewUrl,
    icon: VisibilityIconStyled,
});

// export const deleteAction = (onDelete) => ({
//     title: "Удалить",
//     onClick: onDelete,
//     icon: DeleteForeverIconStyled,
// });
//
// export const archiveAction = (onArchive) => ({
//     title: "Архивировать",
//     onClick: onArchive,
//     icon: InventoryIconStyled,
// });
//
// export const downloadAction = (downloadUrl, props = {}) => ({
//     title: "Скачать",
//     link: downloadUrl,
//     icon: DownloadIconStyled,
//     target: "_blank",
//     ...props,
// });
//
// export const deactivateAction = (onDeactivate) => ({
//     title: "Остановить",
//     onClick: onDeactivate,
//     icon: DangerousIconStyled,
// });
//
// export const pauseAction = (onPause, props = {}) => ({
//     title: "Остановить",
//     onClick: onPause,
//     icon: PauseCircleIconStyled,
//     ...props,
// });
//
// export const proceedAction = (onProceed, props = {}) => ({
//     title: "Запустить",
//     onClick: onProceed,
//     icon: PlayCircleIconStyled,
//     ...props,
// });

export const watchNoteAction = (originalRow, {rules = [], field}) => {
    const onClick = (_e, {openModal}) => {
        openModal({
            component: NotesModal,
            notes: originalRow[field],
        });
    };

    return withButtonRules(
        {rules, data: originalRow},
        {
            color: "primary",
            title: "Просмотреть заметку",
            icon: TextSnippetIcon,
            onClick,
        }
    );
};

export const apiRequestAction = (
    originalRow,
    {url, icon, label, rules = [], method = 'post'}
) => {
    const onClick = (_e, {reloadGridData, reloadTemplate}) => {
        makeApiRequest({
            config: {
                url: completeUrlFromData(url, originalRow),
                method,
            },
        }).then((resp) => {
            if (!!resp) {
                if (resp.reloadTemplate) {
                    reloadTemplate();
                } else {
                    reloadGridData();
                }
            }
        });
    };
    return withButtonRules(
        {rules, data: originalRow},
        {label, onClick, icon}
    );
};

export const linkAction = (originalRow, {url, icon, label, rules = []}) => {
    const onClick = (_e, {navigate}) => {
        const completeUrl = completeUrlFromData(url, originalRow);
        const isAbsoluteUrl = completeUrl.startsWith("http");
        if (isAbsoluteUrl) {
            window.open(completeUrl, "_blank");
            return;
        }
        navigate(completeUrl);
    };

    return withButtonRules(
        {rules, data: originalRow},
        {label, onClick, icon}
    );
};

export const withButtonRules = ({rules = {}, data}, button) => {
    let visible = true;
    let onClick = button.onClick;

    if (rules.visibleWhen) {
        visible = !rules.visibleWhen.some(([field, cond, value]) => {
            if (cond === "is") {
                return data[field] !== value;
            }
            if (cond === "not") {
                return data[field] === value;
            }
            if (cond === "not_empty") {
                return !data[field];
            }
            return false;
        });
    }

    if (rules.confirmModal) {
        const {title, description, confirmText, cancelText} = rules.confirmModal;
        onClick = (_e, handlers) => {
            handlers.openModal({
                component: ConfirmationModal,
                title,
                description,
                confirmText,
                cancelText,
                onConfirm: () => button.onClick(_e, handlers),
            });
        };
    }

    return {...button, visible, onClick};
};

export const formAction = (
    originalRow,
    {url, icon, label, rules = [], title}
) => {
    const onClick = (_e, {openModal, reloadGridData}) => {
        openModal({
            component: FormModal,
            apiUrl: completeUrlFromData(url, originalRow),
            title,
            onSuccess: () => {
                reloadGridData();
            },
        });
    };

    return withButtonRules(
        {rules, data: originalRow},
        {label, onClick, icon}
    );
};
