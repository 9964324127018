import React from "react";

import {Box, FormControlLabel, FormHelperText, FormLabel, RadioGroup} from '@mui/material'
import {Controller, useFormContext} from "react-hook-form";
import {withRules} from "templates/Form/withRules";

import {RadioStyled} from "components/RadioInput/RadioInput.styles";

const RadioInputComponent = ({
                                 label, value, name, dictionary = [], helperText, size = "small"
                             }) => {

    const {
        control,
        formState: {errors: formErrors},
    } = useFormContext();

    const errors = formErrors?.[name];
    const error = !!errors;

    const fieldHelperText = error ? errors?.message : helperText;


    return (<Box pb={1}>
        <FormLabel>{label}</FormLabel>
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({field: {ref, ...rest}}) => (
                <RadioGroup {...rest}>
                    {dictionary.map(({label, value}) => <FormControlLabel
                        value={value}
                        control={<RadioStyled size={size}/>}
                        label={label}
                        key={label}/>)}
                </RadioGroup>
            )}/>
        <FormHelperText>{fieldHelperText}</FormHelperText>
    </Box>)
}

export const RadioInput = withRules(RadioInputComponent);