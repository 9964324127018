import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment } from "@mui/material";

export const DateFilterInputAdornment = (props) => {
  const { children, handleClear, isValue, ...rest } = props;
  return (
    <InputAdornment {...rest}>
      {isValue && (
        <IconButton onClick={handleClear}>
          <ClearIcon />
        </IconButton>
      )}
      {children}
    </InputAdornment>
  );
};
