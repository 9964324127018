import {Box, Grid, Paper, Typography} from "@mui/material";
import {formatter} from "helpers/formatter";

export const DataList = ({meta, data}) => {
    return (
        <Grid container spacing={2} pb={1}>
            {meta.groups.map(({fields, label}) => (
                <Grid item md={12} key={label}>
                    <Paper component={Box} p={2}>
                        <Typography variant={"h5"}>{label}</Typography>
                        {fields.map(({label, field, format}) => {
                            return (
                                <div key={label}>
                                    <Typography fontWeight="bold">{label}:</Typography>
                                    {formatter(data[field], format)}
                                </div>
                            );
                        })}
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};
