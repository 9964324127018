import React, { useCallback, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import dayjs from "dayjs";
import { debounce } from "debounce";

import { DateFilterInputAdornment } from "components/Table/components/Filter/components/DateFilterInputAdornment";

export const DateFilter = ({
  onChange,
  value: initialValue,
  label,
  autoFocus,
}) => {
  const [value, setValue] = useState(initialValue);
  const onChangeDebounced = useCallback(debounce(onChange, 500), []);

  const handleChange = (val) => {
    const formattedVal = val ? val.format("YYYY-MM-DD") : null;
    setValue(formattedVal);
    if (val === null || val.isValid()) {
      onChangeDebounced(formattedVal);
    }
  };

  const clear = () => {
    setValue(null);
    onChange(null);
  };

  return (
    <MuiDatePicker
      label={label}
      value={value ? dayjs(value) : ""}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={clear}>
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      }
      slotProps={{
        textField: {
          size: "small",
          error: false,
          fullWidth: true,
        },
      }}
      slots={{
        inputAdornment: (props) => (
          <DateFilterInputAdornment
            {...props}
            isValue={!!value}
            handleClear={clear}
          />
        ),
      }}
      onChange={handleChange}
      autoFocus={autoFocus}
    />
  );
};
