import { useEffect, useRef } from "react";

import { useApi } from "./useApi";

export const useDictApi = ({
  remoteDict,
  dependsOn,
  disableInitialLoad = true,
  ...props
}) => {
  const apiHandler = useApi({
    ...props,
    url: `dict/${remoteDict}`,
    initialMethod: "post",
    disableInitialLoad: true,
    requestData: {
      dependencies: dependsOn,
    },
  });

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    remoteDict && apiHandler.reload();
  }, [...Object.values(dependsOn)]);

  return { items: apiHandler.data };
};
