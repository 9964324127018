import React, {useContext, useEffect, useMemo, useState} from "react";

import {FormProvider, useForm} from "react-hook-form";
import {formFieldsResolver} from "templates/Form/helpers";

import {FormContainer} from "components/FormContainer";
import {TemplateContext} from "contexts/TemplateContext";
import {useApi} from "hooks/useApi";

export const ApiForm = ({
                            apiUrl,
                            disableInitialLoad,
                            cleanOnSuccess,
                            onSuccess,
                            fields,
                        }) => {
    const methods = useForm();
    const {setError, reset, register, handleSubmit} = methods;

    const {reload: reloadTemplate} = useContext(TemplateContext);

    const {data, dictionaries, onSubmit, loading} = useApi({
        url: apiUrl,
        setError,
        reset,
        disableInitialLoad,
        cleanOnSuccess,
        onSuccess,
    });

    const reloadTrackFields = useMemo(() => {
        return fields.reduce((c, v) => {
            if (v.reloadFormOnChange) {
                c.push(v.name)
            }
            return c
        }, [])
    }, [fields])

    const fieldsKeys = useMemo(() => {
        return fields.reduce((c, v) => {
            c.push(v.name)
            return c
        }, [])
    }, [fields])

    const getTrackedState = () => reloadTrackFields.reduce((c, v) => {
        c[v] = methods.watch(v)
        return c
    }, {})

    const [lastReloadTrackState, setLastReloadTrackState] = useState(null)

    useEffect(() => {
        const trackedFields = getTrackedState()
        if (lastReloadTrackState !== JSON.stringify(trackedFields)) {
            if (lastReloadTrackState !== null) {
                reloadTemplate({_ACTION: 'GET_FIELDS', ...trackedFields}, 'POST').then(() => {
                    const data = methods.watch()
                    const removeFields = Object.keys(data).reduce((c, v) => {
                        if (!fieldsKeys.includes(v)) {
                            c.push(v)
                        }
                        return c
                    }, [])
                    removeFields.forEach(v => {
                            methods.unregister(v)
                        }
                    )
                })
            }

            setLastReloadTrackState(JSON.stringify(trackedFields))
        }
    }, [methods.watch()])

    const fieldsResolved = formFieldsResolver(fields);

    return data || dictionaries || disableInitialLoad ? (
        <FormContainer register={register} onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods} isApiLoading={loading}
                          onSubmit={handleSubmit(onSubmit)}>{fieldsResolved}</FormProvider>
        </FormContainer>
    ) : (
        <></>
    );
};
