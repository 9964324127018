import React from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "templates/Form";

import { useApi } from "hooks/useApi";

export const FormModal = ({
  title,
  open,
  handleClose,
  apiUrl,
  onSuccess = () => {},
}) => {
  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const { meta, data, errorCode, reload } = useApi({
    url: apiUrl,
  });

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {meta && (
          <Form
            meta={meta}
            data={data}
            endpoint={apiUrl}
            onSuccess={handleSuccess}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
