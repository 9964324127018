import React, {useContext} from "react";

import LogoutIcon from "@mui/icons-material/Logout";
import {Divider, Menu, MenuItem} from "@mui/material";

import {AppContext} from "contexts/AppContext";
import {useApi} from "hooks/useApi";
import {UserMenuItem} from "layouts/components/UserMenuItem";

export const UserMenu = ({anchorEl, handleClose}) => {
    const {userMenu = []} = useContext(AppContext);

    const {reload: logout} = useApi({
        url: "logout",
        disableInitialLoad: true,
        initialMethod: "post",
    });

    return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            onClick={handleClose}
        >
            {userMenu.map((menuItem) => <UserMenuItem key={menuItem.label} {...menuItem} />)}
            <Divider/>
            <MenuItem onClick={e => logout()}>
                <LogoutIcon/>
                &nbsp;Выход
            </MenuItem>
        </Menu>
    );
};
