import styled from "@emotion/styled";

export const ChatPopoverContent = styled.div`
  padding: 1em;
  overflow: hidden;

  ${p => p.theme.breakpoints.down('sm')} {
    width: 95vw;
    height: calc(100vh - 56px);
  }

  ${p => p.theme.breakpoints.up('sm')} {
    width: 30em;
    height: 80vh;
  }
`