import React from "react";

import { ReactSelect } from "components/ReactSelect/ReactSelect";

export const DropdownFilter = ({
  dictionary,
  onChange,
  value,
  multi,
  label,
  autoFocus,
  ...props
}) => {
  return (
    <ReactSelect
      isMulti={multi}
      items={dictionary}
      onChange={onChange}
      label={label}
      placeholder={label}
      value={value}
      fullWidth
      autoFocus={autoFocus}
    />
  );
};
