import React from "react";

import { Box, Typography } from "@mui/material";

const PageHeader = ({ label }) => {
  return (
    <Typography variant={"h4"} component={Box} pb={2} pt={1}>
      {label}
    </Typography>
  );
};

export default PageHeader;
