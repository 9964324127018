import {createContext, useContext, useEffect, useState} from "react";

import {ChatIcon} from "components/ChatIcon";
import {ChatPopover} from "components/ChatPopover";
import {AppContext} from "contexts/AppContext";
import {SocketContext} from "contexts/SocketContext";
import {TemplateContext} from "contexts/TemplateContext";

export const ChatContext = createContext({});

export const ChatProvider = ({children}) => {
    const {is_authenticated, is_blocked} = useContext(AppContext)
    const {meta} = useContext(TemplateContext)

    const [open, setOpen] = useState(false);
    const [totalUnread, setTotalUnread] = useState(0)

    const {socket} = useContext(SocketContext)

    const handleTotalUnread = ({total_unread}) => {
        setTotalUnread(total_unread);
    }

    useEffect(() => {
        socket?.on('chat_total_unread', handleTotalUnread);
        socket?.on('chat_message', unreadIncrease)

        return () => {
            socket?.off('chat_total_unread', handleTotalUnread);
            socket?.on('chat_message', unreadIncrease)
        };
    }, [socket]);

    const unreadIncrease = (data) => {
        !open && !data.is_own && setTotalUnread(prev => prev + 1)
    }

    const onOpen = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false)
    }

    if (meta?.disableChat) return null

    return is_authenticated && !is_blocked ? <ChatContext.Provider value={{open, setOpen, setTotalUnread}}>
        {!open ? <ChatIcon onClick={onOpen} messageCount={totalUnread}/> :
            <ChatPopover handleClose={onClose}/>
        }
    </ChatContext.Provider> : null
}