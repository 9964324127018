import {useMemo} from "react";

import {Box, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

import {BlockedText} from "components/TemplateResolver/BlockedText";

export const ErrorCodeResolver = ({errorCode}) => {
    const errorDescription = useMemo(() => {
        switch (errorCode) {
            default:
                return "Ой, кажется что-то сломалось";
            case 403:
                return <BlockedText/>
            case 404:
                return "Страница не найдена";
            case 500:
                return "Ошибка ответа сервера";
        }
    }, [errorCode]);
    return (
        <Box
            display="flex"
            minHeight="calc(100vh - 125px)"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h1" fontWeight="bold" color={grey[800]} align="center">
                    {errorCode}
                </Typography>
                {(typeof (errorDescription) === 'string') ?
                    <Typography variant="h6">
                        {errorDescription}
                    </Typography>
                    : errorDescription
                }
            </Box>
        </Box>
    );
};
