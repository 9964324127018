import {grey} from "@mui/material/colors";
import styled from "styled-components";

export const InputSublabel = styled.div`
  font-size: 0.8em;
  color: ${p => grey[500]};
`;

export const InputSublabelPrefix = styled.span`
  font-weight: bold;
`;