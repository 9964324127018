import {useMemo} from "react";

import {Box, CircularProgress, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {MessageLoadWrap} from "templates/Chat/Chat.styles";
import {Message, NoMessagesIcon, NoMessagesText, NoMessagesWrap} from "templates/Chat/components/ChatContent.styles";
import {ChatMessageDateTime} from "templates/Chat/components/ChatMessageDateTime";

export const ChatContent = ({messages = [], loading}) => {
    const placeholder = useMemo(() => {
        if (loading) return <MessageLoadWrap><CircularProgress/></MessageLoadWrap>
        if (messages.length === 0) return <NoMessagesWrap pt={2} pr={2}>
            <NoMessagesIcon icon={'chat'}/>
            <NoMessagesText>Нет сообщений</NoMessagesText>
        </NoMessagesWrap>
        return null
    }, [messages])

    return <>
        {placeholder}
        {messages.map(message => {
            return <Message pt={2} pr={2} data-message-id={message.id} data-is-new={!!message.isNew} key={message.id}>
                <div>{message.message}</div>
                <Box display="flex" justifyContent="space-between" pt={1}>
                    <ChatMessageDateTime created={message.created_at}/>
                    <Typography component={Box} pr={1} fontWeight={300} color={grey['500']} fontSize={'12px'}>
                        {message.author_name}
                    </Typography>
                </Box>
            </Message>
        })}
    </>
}