import React, { useContext } from "react";

import { Box, Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { IconComponent } from "components/IconComponent";
import { ModalContext } from "contexts/ModalContext";
import { TemplateContext } from "contexts/TemplateContext";

export const ActionGroup = ({ buttons }) => {
  const { openModal } = useContext(ModalContext);
  const { reload, dataExport } = useContext(TemplateContext);

  const navigate = useNavigate();
  const agOnClickHandler = (onClick) => (e) => {
    return onClick(e, { openModal, reload, navigate, dataExport });
  };

  return (
    <>
      {buttons.map(
        ({
          color = "success",
          icon,
          label = "",
          disabled,
          tooltip,
          onClick,
        }) => {
          const button = (
            <Button
              color={color}
              onClick={agOnClickHandler(onClick)}
              key={label}
              disabled={disabled}
            >
              <Box component={IconComponent} mr={0.5} icon={icon} />
              {label}
            </Button>
          );

          if (tooltip) {
            return (
              <Tooltip title={tooltip} key={label}>
                <span>{button}</span>
              </Tooltip>
            );
          }

          return button;
        }
      )}
    </>
  );
};
