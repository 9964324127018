import React from "react";

import { FormControl, FormHelperText } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { ReactSelectAddress } from "components/ReactSelectAddress";

export const DictTextInput = (props) => {
  const {
    name,
    helperText,
    fullWidth,
    size = "small",
    label,
    remoteDict,
    required,
    value,
  } = props;

  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const errors = formErrors?.[name];
  const error = !!errors;
  const fieldHelperText = error ? errors?.message : helperText;

  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      error={error}
      size={size}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={({ field: { ref, ...rest } }) => (
          <ReactSelectAddress
            remoteDict={remoteDict}
            label={label}
            required={required}
            {...rest}
          />
        )}
      />
      <FormHelperText>{fieldHelperText}</FormHelperText>
    </FormControl>
  );
};
