import styled from "@emotion/styled";
import {IconButton} from "@mui/material";

export const ChatsListWrap = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 2em 2em 1em 2em;
`

export const StyledIconButton = styled(IconButton)`
  background-color: ${p => p.theme.palette.primary.contrastText};
  color: ${p => p.theme.palette.primary.main};

  &:hover {
    background-color: ${p => p.theme.palette.primary.main};
    color: ${p => p.theme.palette.primary.contrastText};
  }

  &[data-active='true'] {
    background-color: ${p => p.theme.palette.primary.main};
    color: ${p => p.theme.palette.primary.contrastText};
  }
`