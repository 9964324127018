import "react-toastify/dist/ReactToastify.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "dayjs/locale/ru";

import React, {useEffect} from "react";

import {ThemeProvider} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {customTheme} from "customTheme";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import {ToastContainer} from "react-toastify";
import {ThemeProvider as StyledComponentsThemeProvider} from "styled-components";

import {AppContext} from "contexts/AppContext";
import {ChatProvider} from "contexts/ChatContext";
import {ModalProvider} from "contexts/ModalContext";
import {SocketProvider} from "contexts/SocketContext";
import {TemplateProvider} from "contexts/TemplateContext";
import {useApi} from "hooks/useApi";
import {useLegalApprove} from "hooks/useLegalApprove";

dayjs.extend(relativeTime);
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

dayjs.locale('ru')

function App() {
    const {data, loading, reload} = useApi({url: "app"});

    useEffect(() => {
        document.title = data?.appName || "";
    }, [data?.appName]);

    useLegalApprove({data})

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
            <ThemeProvider theme={customTheme}>
                <StyledComponentsThemeProvider theme={customTheme}>
                    <AppContext.Provider value={{...data, loading, reload}}>
                        <SocketProvider>
                            <ModalProvider>
                                <TemplateProvider>
                                    <ChatProvider/>
                                </TemplateProvider>
                                <ToastContainer/>
                            </ModalProvider>
                        </SocketProvider>
                    </AppContext.Provider>
                </StyledComponentsThemeProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
