import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export const MultiselectFilter = ({
  label,
  value = [],
  onChange,
  dictionary = [],
}) => {
  const handleChange = (e) => {
    const newValue = value.filter((v) => String(v) !== e.target.value);
    if (e.target.checked) {
      const dictRecord = dictionary.find(
        (d) => String(d.value) === e.target.value
      );
      if (dictRecord) {
        newValue.push(dictRecord.value);
      }
    }

    onChange(newValue);
  };

  return (
    <FormGroup onChange={handleChange}>
      {dictionary.map(({ name, value: itemVal }) => (
        <FormControlLabel
          key={itemVal}
          control={<Checkbox checked={value.includes(itemVal)} />}
          label={name}
          value={itemVal}
        />
      ))}
    </FormGroup>
  );
};
