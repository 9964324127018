import {useContext, useEffect} from "react";

import {Box, Paper, TablePagination, Typography} from "@mui/material";
import {LogRecord} from "templates/Log/components/LogRecord";

import {FiltersList} from "components/Table/components/FiltersList";
import {DEFAULT_PER_PAGE_OPTIONS, labelDisplayedRows} from "constants/grid";
import {TemplateContext} from "contexts/TemplateContext";
import {useGridApi} from "hooks/useGridApi";

export const Log = ({meta, endpoint, key}) => {
    const {data = [], reload, gridControls, loading: gridLoading} = useGridApi({
        url: meta?.apiUrl || endpoint,
        disableInitialLog: true,
    });

    const {reloading} = useContext(TemplateContext)

    useEffect(() => {
        if (key !== "root" && reloading) {
            reload();
        }
    }, [reloading])

    const {filterControls} = gridControls;

    return (
        <Paper component={Box} p={2} pb={2}>
            <Typography variant={"h5"}>{meta.label}</Typography>
            {filterControls?.filters &&
                Object.keys(filterControls.filters).map((column) => (
                    <FiltersList
                        key={column}
                        filters={filterControls.filters?.[column]}
                        activeFilters={filterControls.activeFilters?.[column]}
                        onFilterChange={filterControls.onFilterChange(column)}
                        firstElementAutoFocus={false}
                    />
                ))}

            <Box pt={2}>
                {data.length
                    ? data.map((logRecord) => (
                        <LogRecord
                            {...logRecord}
                            showRecordLabel={meta.showRecordLabel}
                            key={logRecord.id}
                            modelsLogMeta={meta.modelsLogMeta}
                        />
                    ))
                    : "Нет записей"}
            </Box>

            <TablePagination
                component="div"
                rowsPerPageOptions={DEFAULT_PER_PAGE_OPTIONS}
                labelRowsPerPage="На странице:"
                labelDisplayedRows={labelDisplayedRows}
                {...gridControls.paginationControls}
            />
        </Paper>
    );
};
