import { Box, Typography } from "@mui/material";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";

import { CleanLink } from "../CleanLink";

export const Breadcrumbs = ({ path = [] }) => {
  return (
    <MuiBreadcrumbs aria-label="breadcrumb" component={Box} pt={2}>
      {path.length === 0 ? (
        <Typography color="text.primary">Главная</Typography>
      ) : (
        <CleanLink to="/">Главная</CleanLink>
      )}
      {path.map(({ link, label }, index) => {
        return index + 1 !== path.length ? (
          <CleanLink key={label} to={link}>
            {label}
          </CleanLink>
        ) : (
          <Typography key={label} color="text.primary">
            {label}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
};
