import React, { forwardRef } from "react";

import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { markupParser } from "helpers/markupParser";
import { useFormContext } from "react-hook-form";
import { withRules } from "templates/Form/withRules";

const CheckboxInputComponent = forwardRef(
  (
    { label, name, size = "small", helperText = "", onChange, ...props },
    ref
  ) => {
    const {
      register,
      watch,
      formState: { errors: formErrors },
    } = useFormContext();

    const errors = formErrors?.[name];
    const error = !!errors;

    const registerProps = register(name);

    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              ref={ref}
              checked={!!watch(name)}
              {...registerProps}
              onChange={onChange ?? registerProps.onChange}
              size={size}
            />
          }
          error={errors}
          label={
            <>
              {markupParser(label)}
              <FormHelperText error={error}>
                {error ? errors?.message : helperText}
              </FormHelperText>
            </>
          }
        />
      </>
    );
  }
);

export const CheckboxInput = withRules(CheckboxInputComponent);
